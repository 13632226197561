import { ROUTES } from '../config/config';

export const KidsAppHelper = {
  signupUrl: `${ROUTES.COMMON_API_URL}/user-management/signup`,
  verifyEmailUrl: `${ROUTES.COMMON_API_URL}/user-management/verify-email`,
  resendVerifyEmailUrl: `${ROUTES.COMMON_API_URL}/user-management/verify-email/resend`,
  userUpdateUrl: `${ROUTES.COMMON_API_URL}/user-management/users`,
  loginUrl: `${ROUTES.COMMON_API_URL}/user-management/login`,
  loginUrlFB: `${ROUTES.COMMON_API_URL}/user-management/login-fb`,
  loginUrlGoogle: `${ROUTES.COMMON_API_URL}/user-management/google-authenticate`,
  resetPassword: `${ROUTES.COMMON_API_URL}/user-management/reset-password`,
  passwordResetToken: `${ROUTES.COMMON_API_URL}/user-management/password-reset-token`,
  resetPasswordOtp: `${ROUTES.COMMON_API_URL}/user-management/verify-reset-password-token`,
  profileUploadUrl: `${ROUTES.COMMON_API_URL}/user-management/profile-image`,
  countries: `${ROUTES.COMMON_API_URL}/user-management/country`,
  states: `${ROUTES.COMMON_API_URL}/user-management/states`,
  cities: `${ROUTES.COMMON_API_URL}/user-management/cities`,
  sendInvitation: `${ROUTES.COMMON_API_URL}/user-management/invitation/send`,
  checkInvitation: `${ROUTES.COMMON_API_URL}/user-management/invitation`,
  validateInvitationPasscode: `${ROUTES.COMMON_API_URL}/user-management/invitation/valdiateinvitationpasscode`,
  acceptInvitation: `${ROUTES.COMMON_API_URL}/user-management/invitation/acceptinvitation`,
  firebaseAuth: `${ROUTES.COMMON_API_URL}/user-management/firebase-auth`
};

export const KidsAppHelperPost = {
  child: `${ROUTES.COMMON_API_URL}/post-management/children`,
  addChildImage: `${ROUTES.COMMON_API_URL}/post-management/child-image-upload`,
  myChild: `${ROUTES.COMMON_API_URL}/post-management/my-child`,
  otherChild: `${ROUTES.COMMON_API_URL}/post-management/other-child`,
  media: `${ROUTES.COMMON_API_URL}/post-management/media`,
  post: `${ROUTES.COMMON_API_URL}/post-management/post`,
  event: `${ROUTES.COMMON_API_URL}/post-management/event`,
  diaryEntry: `${ROUTES.COMMON_API_URL}/post-management/diary-entry`,
  getAWSImageUrl: `${ROUTES.COMMON_API_URL}/sign-url-service/sign-url`,
  searchUrl: `${ROUTES.COMMON_API_URL}/post-management/search`,
  familyMembers: `${ROUTES.COMMON_API_URL}/post-management/family-members`
};
