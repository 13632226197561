import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import 'react-datepicker/dist/react-datepicker.css';
import InputTypeErrors from '../inputTypeErrors/InputTypeErrors';

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [startDate, setStartDate] = useState(new Date());

  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };
  const years = range(1990, getYear(new Date()) + 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return (
    <>
      <label>{props.label}</label>
      <div className="form-group">
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </button>
            </div>
          )}
          className="form-control"
          {...field}
          {...props}
          selected={startDate}
          autoComplete="off"
          onChange={date => {
            setStartDate(date);
            if (date) {
              setFieldValue(field.name, moment(date).format('yyyy-MM-DD'));
            } else {
              setFieldValue(field.name, '');
            }
          }}
        />
      </div>
      <InputTypeErrors errors={meta} />
    </>
  );
};
DatePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired
};

export { DatePickerField };
