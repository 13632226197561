import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import { CommonMenuItem } from '../../commonMenuItem/CommonMenuItem';
import { PopupModal } from '../../popupModal/PopupModal';
import Icon from '../../../../icomoon/icon';
import { InviteToFamliyForm } from '../../../home/inviteToFamliyForm/InviteToFamilyForm';
import { LanguageHelper } from '../../../../../helpers/languageHelpers/En';

const InviteToFamilyMenuItem = props => {
  const { disable } = props;
  const [initailMenuItem, setMenuItem] = useState({
    invitation: false
  });
  const createInvitation = value => {
    setMenuItem({
      invitation: value
    });
  };

  return (
    <div>
      {!disable ? (
        <div onClick={() => createInvitation(true)} role="button">
          <CommonMenuItem
            buttonClass="nav-link nav-link-btn d-flex align-items-center"
            iconClass="bt-icon-wrapper icon-circle-45 icon-circle-light-green"
            title="Invite to Family "
            description=""
            iconComponent={
              <Icon
                icon="icon-family"
                size={25}
                color="#2EC99F"
                className="nl-icon"
              />
            }
          />
        </div>
      ) : (
        <div>
          <CommonMenuItem
            buttonClass="nav-link nav-link-btn d-flex align-items-center"
            iconClass="bt-icon-wrapper icon-circle-45 icon-circle-light-green"
            title="Invite to Family "
            description=""
            iconComponent={
              <Icon
                icon="icon-family"
                size={25}
                color="#2EC99F"
                className="nl-icon"
              />
            }
          />
        </div>
      )}
      <PopupModal
        closable={false}
        centered
        footer={null}
        visible={initailMenuItem.invitation}
        onOk={() => createInvitation(false)}
        onCancel={() => createInvitation(false)}
        backBtn={false}
        closeBtn
        popupTitle={LanguageHelper.INVITE_TO_FAMILY_MEMBERS}
        modalStyle="ant-modal default-modal"
        elementKey={uuid()}
      >
        <InviteToFamliyForm onCancel={createInvitation} />
      </PopupModal>
    </div>
  );
};

InviteToFamilyMenuItem.defaultProps = {
  disable: false
};

InviteToFamilyMenuItem.propTypes = {
  disable: PropTypes.bool
};

export { InviteToFamilyMenuItem };
