import firebase from 'firebase/app';
import firestore from 'firebase/firestore';
import auth from 'firebase/auth';
import database from 'firebase/database';

const settings = { timestampsInSnapshots: true };

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

if (typeof window !== 'undefined') {
  firebase.initializeApp(config);
}

export default firebase;
