import * as ApiConnector from '../ApiConnector';
import { KidsAppHelperPost } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const addDiaryEntry = async (payload, id, edit) => {
  try {
    if (edit) {
      const { data } = await ApiConnector.put(
        `${KidsAppHelperPost.diaryEntry}/${id}`,
        payload
      );
      return data;
    }
    const { data } = await ApiConnector.post(
      KidsAppHelperPost.diaryEntry,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const getDiaryEntry = async id => {
  try {
    const { data } = await ApiConnector.get(
      ` ${KidsAppHelperPost.diaryEntry}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const deleteDiaryEntry = async id => {
  try {
    const { data } = await ApiConnector.del(
      ` ${KidsAppHelperPost.diaryEntry}/${id}`,
      {
        id
      }
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
