/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { getSingleData } from '../../../config/localStorage';
import { Header } from './header';
import { LeftLayout } from './leftCol';
import { RightLayout } from './rightCol';

const DashboardLayout = props => {
  const layOutBgImage = 'outer-container bg-image-welcome';
  console.log(props);

  return (
    <div className="dashboard">
      <div className="dash-navbar-outer fixed-top d-flex align-items-center">
        <Header search={props.children[1].props.location.search !== ''} />
      </div>
      <div className="dash-container">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <LeftLayout
                childId={
                  props.children[1].props.match.params.child_id !== undefined
                    ? props.children[1].props.match.params.child_id
                    : null
                }
                myChild={
                  props.children[1].props.location.state !== undefined
                    ? props.children[1].props.location.state.myChild
                    : null
                }
                search={
                  props.children[1].props.location.search !== '' &&
                  props.children[1].props.location.search
                }
                userId={JSON.parse(getSingleData('current-user')).id}
              />
            </div>
            <div className="col-md-9">
              <RightLayout>{props.children}</RightLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardLayout };
