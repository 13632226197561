/* -------------------------------------------------------------
 * unique key namespace used by combineReducers.
 * By convention it will match the directory structure to
 * make it easy to locate the src.
 * Also action types will prefix with the capitalized version
 * -------------------------------------------------------------
 */

export const key = 'avatarImages';

/* -----------------------------
 * Actions
 * -----------------------------
 */

// retrieve filter data
export const GET_AVATAR_IMAGE_LIST = 'GET_AVATAR_IMAGE_LIST';
export const GET_AVATAR_IMAGE_SUCCESS = 'GET_AVATAR_IMAGE_SUCCESS';
export const GET_AVATAR_IMAGE_ERROR = 'GET_AVATAR_IMAGE_ERROR';

// export const GET_SINGLE_ACTION_ITEM_LIST = 'GET_SINGLE_ACTION_ITEM_LIST';
// export const GET_SINGLE_ACTION_ITEM_LIST_SUCCESS =
//   'GET_SINGLE_ACTION_ITEM_LIST_SUCCESS';
// export const GET_SINGLE_ACTION_ITEM_LIST_ERROR =
//   'GET_SINGLE_ACTION_ITEM_LIST_ERROR';

export const actionTypes = {
  GET_AVATAR_IMAGE_LIST,
  GET_AVATAR_IMAGE_SUCCESS,
  GET_AVATAR_IMAGE_ERROR

  //   GET_SINGLE_ACTION_ITEM_LIST,
  //   GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  //   GET_SINGLE_ACTION_ITEM_LIST_ERROR
};

/* -----------------------------
 * Action Creators
 * -----------------------------
 */
export const getAvatarImageList = request => ({
  type: GET_AVATAR_IMAGE_LIST,
  payload: request
});

export const getAvatarImageListSuccess = (imageUrl, imageKey) => ({
  type: GET_AVATAR_IMAGE_SUCCESS,
  payload: imageUrl,
  imageKey
});

export const getAvatarImageListError = error => ({
  type: GET_AVATAR_IMAGE_ERROR,
  payload: error
});

// export const getSingleActionItemList = request => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST,
//   payload: request
// });

// export const getSingleActionItemListSuccess = response => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
//   payload: response
// });

// export const getSingleActionItemListError = error => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_ERROR,
//   payload: error
// });

export const actions = {
  getAvatarImageList,
  getAvatarImageListSuccess,
  getAvatarImageListError

  //   getSingleActionItemList,
  //   getSingleActionItemListSuccess,
  //   getSingleActionItemListError
};
