import React from 'react';
import timeline1 from '../../assets/images/home/special-moments.png';
import timeline2 from '../../assets/images/home/save-moment.png';
import timeline3 from '../../assets/images/home/invite-people.png';
import slide01 from '../../assets/images/home/slide-01.png';
import slide02 from '../../assets/images/home/slide-02.png';
import slide03 from '../../assets/images/home/slide-03.png';
import slide04 from '../../assets/images/home/slide-04.png';
import slide05 from '../../assets/images/home/slide-05.png';
import ImageCarousel from '../imageCarousel/ImageCarousel';
import { TitleLayout } from './TitleLayout';

const LoginLayout = props => {
  return (
    <div className="outer-container">
      <TitleLayout />
      <div className="container-full">
        <div className="container-inner">
          <div className="box-center">
            <div className="timeline">
              {/* This is fixed and need to be changed when slider is working */}
              {/* Set 1 */}
              <div className="timeline-mom">
                <div>
                  All your special moments, organized together in one place, to
                  cherish. <span>Forever</span>
                </div>
              </div>
              <img src={timeline1} alt="Special Moments" />

              {/* Set 2 */}
              {/* <div className="timeline-mom">
                <div>Save every photo, event, memo or Moment, Live them later, anytime, anywhere.</div>
              </div>
              <img src={timeline2} alt="Save Moment" /> */}

              {/* Set 3 */}
              {/* <div className="timeline-mom">
                <div>Invite those close to you and build your family tree with the people who mean the most.</div>
              </div>
              <img src={timeline3} alt="Invite People" /> */}
            </div>
            <div className="main-wrapper">
              <div className="mw-left d-none d-md-block">
                <ImageCarousel
                  axis="vertical"
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  location="dashboard"
                >
                  <div className="image-container">
                    <img alt="" src={slide01} />
                  </div>
                  <div className="image-container">
                    <img alt="" src={slide02} />
                  </div>
                  <div className="image-container">
                    <img alt="" src={slide03} />
                  </div>
                  <div className="image-container">
                    <img alt="" src={slide04} />
                  </div>
                  <div className="image-container">
                    <img alt="" src={slide05} />
                  </div>
                </ImageCarousel>
              </div>
              <div className="mw-right">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginLayout };
