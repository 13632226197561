const API = {
  PROTOCOL: process.env.REACT_APP_PROTOCOL,
  HOST: process.env.REACT_APP_HOST,
  VERSION: process.env.REACT_APP_API_VERSION,
  PORT: process.env.REACT_APP_PORT,
  API_URL: process.env.REACT_APP_API_URL,
  APP: process.env.REACT_APP_ENV
};
let BASE_URL = '';
if (API.APP === 'local') {
  BASE_URL = `${API.PROTOCOL}://${API.HOST}:${API.PORT}`; // local
} else {
  BASE_URL = API.API_URL; // Server
}

const PATHS = {
  COMMON_API: '/v1'
};

module.exports = {
  ROUTES: {
    COMMON_API_URL: BASE_URL + PATHS.COMMON_API
  }
};
