import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import {
  key as userChildrenState,
  reducer as userChildrenReducer
} from './features/userChildren/index';

import {
  key as otherChildrenState,
  reducer as otherChildrenReducer
} from './features/otherChildren/index';
import {
  key as timeLineState,
  reducer as timeLineReducer
} from './features/timeLine/index';

import {
  key as avatarImages,
  reducer as avatarImagesReducer
} from './features/avatarImages/index';

export const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    [userChildrenState]: userChildrenReducer,
    [otherChildrenState]: otherChildrenReducer,
    [timeLineState]: timeLineReducer,
    [avatarImages]: avatarImagesReducer
  });
