import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import './style/bootstrap.min.css';
import 'antd/dist/antd.css';
import './style/css/main.css';
// import './fonts/h5p-font-awesome.min.css';

ReactDOM.render(
  <>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
      integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
      crossOrigin="anonymous"
    />
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
