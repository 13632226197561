import * as Yup from 'yup';

//  email validation
export const emailYupSchema = Yup.string()
  .required('Please enter a valid Email Address. ex: example@email.com')
  .email('Please enter a valid Email Address. ex: example@email.com')
  .max(255, 'Maximum 255 characters are allowed');

//  phone number validation
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneNumberYupSchema = Yup.string()
  .min(7, 'Phone number length must be at least 7 characters long')
  .matches(phoneRegExp, 'Please enter a valid Phone Number ex: +177700000')
  .required('Please enter a valid Phone Number ex: +177700000');

//  passcode validation
export const passCodeYupSchema = Yup.string()
  .length(4, 'passcode is not valid')
  .required('Required');

//  fname validation
export const firstNameYupSchema = Yup.string()
  .required('Please enter a first name ')
  .min(2, 'First Name must be between 2 and 26 characters')
  .max(26, 'First Name must be between 2 and 26 characters');

//  lname validation
export const lastNameYupSchema = Yup.string()
  .required('Please enter a last name ')
  .min(2, 'Last Name must be between 2 and 26 characters')
  .max(26, 'Last Name must be between 2 and 26 characters');

//  Date Of Birth validation
export const dateOfBirthYupSchema = Yup.string().required(
  'Please select a valid date'
);

//  address validation
export const addressLine1YupSchema = Yup.string().required(
  'Please enter a address 01'
);
export const addressLine2YupSchema = Yup.string().required(
  'Please enter a address 02'
);
export const addressStateYupSchema = Yup.string().required(
  'Please select a state'
);
export const addressCountryYupSchema = Yup.string().required(
  'Please select a country'
);
export const addressZipYupSchema = Yup.string().required(
  'Please enter a zip code'
);
export const addressCityYupSchema = Yup.string().required(
  'Please select a city'
);
// password validation
export const passwordYupSchema = Yup.string()
  .required('Please enter a password')
  .matches(
    /(?=^.{8,16}$)((?=.*[0-9])(?=.*[A-Z]))^.*$/,
    'Password has to be between 8-16 characters with atleast one number and one uppercase charachter'
  );

export const loginPasswordYupSchema = Yup.string().required(
  'Please enter a password '
);

// confirm password validation
export const confirmPasswordYupSchema = Yup.string()
  .required('Please confirm password')
  .when('password', {
    is: val => !!(val && val.length > 0),
    then: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match')
  });

export const genderYupSchema = Yup.string().required('Please select gender');

export const relationshipYupSchema = Yup.string().required(
  'Please select relationship'
);

export const albumTitleYupSchema = Yup.string().required(
  'Please enter a album title'
);

export const eventTitleYupSchema = Yup.string().required(
  'Please enter a event title'
);

export const locationYupSchema = Yup.string().required('Please add a location');

export const diaryEntryDescriptionYupSchema = Yup.string().required(
  'Please enter a description '
);

export const diaryEntryTitleYupSchema = Yup.string().required(
  'Please enter a diary entry title'
);
