import React, { useState } from 'react';
import { useField } from 'formik';

import InputTypeErrors from '../inputTypeErrors/InputTypeErrors';

const PasswordField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const regex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );

  const checkLength = word => {
    if (word.length <= 0) {
      return 0;
    }
    if (word.length < 8) {
      return 1;
    }
    if (word.length < 10) {
      return 2;
    }
    if (word.length >= 10) {
      return 3;
    }
  };

  const checkRegex = word => {
    const found = word.match(regex);
    if (found == null) {
      return 0;
    }
    return 1;
  };

  let testedResult = checkLength(meta.value) + checkRegex(meta.value);

  const validatePasswordStrong = () => {
    switch (testedResult) {
      case 0:
        return '';
      case 1:
        return 'Weak';
      case 2:
        return 'Good';
      case 3:
        return 'Good';
      default:
        return 'Strong';
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const strengthClass = ['strength-meter mt-2 '].join(' ').trim();

  const strengthMessageClass = ['strength-message'].join(' ').trim();

  return (
    <div style={{ width: '100%' }}>
      {props.screen !== 'login' ? (
        <div>
          <div className={strengthClass}>
            <div className="strength-meter-fill" data-strength={testedResult} />
          </div>

          <div className={strengthMessageClass}>
            Password Strength:
            <span className={validatePasswordStrong()}>
              {validatePasswordStrong()}
            </span>
          </div>
        </div>
      ) : null}
      <div className="form-group">
        <label>{label}</label>
        <input
          className="form-control"
          type={passwordShown ? 'text' : 'password'}
          {...field}
          {...props}
        />

        {passwordShown ? (
          <svg
            width="2em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-eye-fill eye"
            fill="currentColor"
            onClick={togglePasswordVisiblity}
          >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
          </svg>
        ) : (
          <svg
            width="2em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-eye-slash-fill eye"
            fill="currentColor"
            onClick={togglePasswordVisiblity}
          >
            <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z" />
            <path d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z" />
          </svg>
        )}

        <InputTypeErrors errors={meta} />
      </div>
    </div>
  );
};

export { PasswordField };
