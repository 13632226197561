import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { useDispatch } from 'react-redux';

import { da } from 'date-fns/locale';
import { getEvent } from '../../../../../api/userEvent/UserEvent';
import { CommonMenuItem } from '../../commonMenuItem/CommonMenuItem';
import { AddEventForm } from '../../../home/addEventForm/AddEventForm';
import { PopupModal } from '../../popupModal/PopupModal';
import Icon from '../../../../icomoon/icon';
import { LanguageHelper } from '../../../../../helpers/languageHelpers/En';
import { addATimelineDataListSuccess } from '../../../../../features/timeLine/actions';

const EventMenuItem = props => {
  const { type } = props;
  const dispatch = useDispatch();
  const [initailMenuItem, setMenuItem] = useState({
    createEvent: false
  });
  const createEvent = (value, data = null) => {
    setMenuItem({
      createEvent: value
    });
    if (data !== null) {
      getEvent(data.event_id)
        .then(response => {
          dispatch(addATimelineDataListSuccess(response.data));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div
        className="wel-btn-wrap"
        onClick={() => createEvent(true)}
        role="button"
      >
        {type !== 'timeline' ? (
          <CommonMenuItem
            buttonClass="nav-link nav-link-btn d-flex align-items-center"
            iconClass="bt-icon-wrapper icon-circle-45 icon-circle-yellow"
            title="Event"
            description="Share an event on Timeline."
            iconComponent={
              <Icon
                icon="icon-event"
                size={20}
                color="#ffffff"
                className="nl-icon"
              />
            }
          />
        ) : (
          <div className="wel-btn wel-yellow-btn d-flex align-items-center">
            <div>
              <div className="bt-title">Event</div>
              <div className="bt-description">Add an event to Timeline.</div>
            </div>
          </div>
        )}
      </div>
      <PopupModal
        closable={false}
        centered
        footer={null}
        visible={initailMenuItem.createEvent}
        onOk={() => createEvent(false)}
        onCancel={() => createEvent(false)}
        backBtn={false}
        closeBtn
        popupTitle={LanguageHelper.CREATE_EVENT}
        modalStyle="ant-modal default-modal"
        elementKey={uuid()}
      >
        <AddEventForm onCancel={createEvent} />
      </PopupModal>
    </>
  );
};

EventMenuItem.defaultProps = {
  type: null
};

EventMenuItem.propTypes = {
  type: PropTypes.string
};
export { EventMenuItem };
