import React, { useState } from 'react';

import { CommonMenuItem } from '../../commonMenuItem/CommonMenuItem';
import Icon from '../../../../icomoon/icon';

const DiaryEntryMenuItem = () => {
  const [initailMenuItem, setMenuItem] = useState({
    createEvent: false
  });
  const createEvent = value => {
    setMenuItem({
      createEvent: value
    });
  };

  return (
    <div>
      <div>
        <CommonMenuItem
          buttonClass="nav-link nav-link-btn d-flex align-items-center"
          iconClass="bt-icon-wrapper icon-circle-45 icon-circle-light-green"
          title="Diary Entries"
          description=""
          iconComponent={
            <Icon
              icon="icon-diary-entry"
              size={25}
              color="#2EC99F"
              className="nl-icon"
            />
          }
        />
      </div>
    </div>
  );
};

export { DiaryEntryMenuItem };
