export const dateConvert = dateToConvert => {
  let timeFormDOB = '';
  const date = new Date(dateToConvert);
  const diff = new Date().getTime() - date.getTime();
  const days = Math.floor(diff / (1000 * 3600 * 24));
  const years = Math.floor(diff / (1000 * 3600 * 24 * 365));

  const diffDaysfromYears = days - years * 365;
  const months = Math.floor(diffDaysfromYears / 30);
  const diffDaysfromMonths = diffDaysfromYears - months * 30;
  const weeks = Math.floor(diffDaysfromMonths / 7);
  const diffDaysfromWeeks = diffDaysfromMonths - weeks * 7;

  if (years > 0 && years !== 1) {
    timeFormDOB += ` ${years} years`;
  } else if (years === 1) {
    timeFormDOB += ` ${years} year`;
  }

  if (months > 0 && months !== 1) {
    timeFormDOB += ` ${months} months`;
  } else if (months === 1) {
    timeFormDOB += ` ${months} month`;
  }

  if (weeks > 0 && weeks !== 1) {
    timeFormDOB += ` ${weeks} weeks`;
  } else if (weeks === 1) {
    timeFormDOB += ` ${weeks} week`;
  }

  if (diffDaysfromWeeks > 1) {
    timeFormDOB += ` ${diffDaysfromWeeks} days`;
  } else if (diffDaysfromWeeks === 1) {
    timeFormDOB += ' 01 day';
  }
  return timeFormDOB;
};
