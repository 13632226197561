import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Switch } from 'antd';
import isNil from 'lodash/isNil';

import { UserAvatar } from '../../../dashboard/UserAvatar';
import { DropdownMenuItem } from '../../../dashboard/common/dropdownMenuItem/DropdownMenuItem';
import { NotificationDrawer } from '../../../dashboard/common/notificationDrawer/NotificationDrawer';
import Icon from '../../../icomoon/icon';

import logo from '../../../../assets/images/logo.svg';
import avatar from '../../../../assets/images/login.jpg';
import { AlbumMenuItem } from '../../../dashboard/common/leftCol/leftColMenuItem/AlbumMenuItem';
import { EventMenuItem } from '../../../dashboard/common/leftCol/leftColMenuItem/EventMenuItem';
import { DiaryEntryMenuItem } from '../../../dashboard/common/leftCol/leftColMenuItem/DiaryEntryMenuItem';
import {
  AcceptInvitationMenuItem,
  InviteToFamilyMenuItem,
  ManageChildrenMeunItem
} from '../../../dashboard/common/leftCol/leftColMenuItem';
import { clearAllData, getSingleData } from '../../../../config/localStorage';
import { SearchBar } from '../../../dashboard/search/SearchBar';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const Header = props => {
  const { search } = props;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const logout = () => {
    clearAllData();
    history.push('/login');
  };
  const profile = () => {
    history.push(`/profile/${JSON.parse(getSingleData('current-user'))._id}`);
  };

  const profileMenu = (
    <div onClick={() => profile()} role="button">
      <Icon
        icon="icon-user-line"
        size={25}
        color="#2EC99F"
        className="nl-icon"
      />
      Profile
    </div>
  );

  const SettingMenu = (
    <div>
      <Icon icon="icon-setting" size={25} color="#2EC99F" className="nl-icon" />
      <a href="#">Setting</a>
    </div>
  );

  const darkMoodMenu = (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <Icon icon="icon-moon" size={25} color="#2EC99F" className="nl-icon" />
        <a href="#">Dark Mood</a>
      </div>
      <Switch defaultChecked onChange={onChange} className="sw-dark-mood" />
    </div>
  );

  const logoutMenu = (
    <div onClick={() => logout()} role="button">
      <Icon icon="icon-logout" size={25} color="#2EC99F" className="nl-icon" />
      <a>Logout</a>
    </div>
  );

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menuWebItems = [profileMenu, SettingMenu, darkMoodMenu, logoutMenu];

  const menuMobileItems = [
    profileMenu,
    SettingMenu,
    darkMoodMenu,
    'divider',
    <InviteToFamilyMenuItem />,
    <AcceptInvitationMenuItem />,
    <ManageChildrenMeunItem />,
    'divider',
    logoutMenu
  ];
  const letColCreatemenuItems = [
    <DiaryEntryMenuItem />,
    <AlbumMenuItem />,
    <EventMenuItem />
  ];

  const submitData = values => {
    if (values.search.length > 2) {
      history.push({
        pathname: '/search',
        search: `?limit=10&page=1&filter=all&search=${values.search}`
      });
    } else {
      ToastrSerivce({
        type: 'error',
        message: 'please enter 3 or more characters to search'
      });
    }
  };

  return (
    <>
      <NotificationDrawer
        className="dash-drawer notifications-drawer"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        setNotificationCount={setNotificationCount}
      />
      <div className="container">
        <div className="row align-items-center">
          <div className="col col-md-2">
            <a href="/">
              <div className="nav-left">
                <img alt="Logo" src={logo} />
              </div>
            </a>
          </div>
          <div className="col col-md-7">
            <div className="nav-center">
              {!search && <SearchBar onSubmit={submitData} initiallValue="" />}
              <div className="d-block d-sm-none">
                <div className="nav-link d-flex align-items-center">
                  <div className="bt-icon-wrapper icon-circle-45 icon-circle-light-green">
                    <Icon
                      icon="icon-search"
                      size={20}
                      color="#2EC99F"
                      className="nl-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-3">
            <div className="nav-right d-flex align-items-center justify-content-end">
              {/* This need to be visibled only on desktop */}
              <a href="/">
                <div className="d-none d-sm-block">
                  <div className="nav-link d-flex align-items-center">
                    <div className="bt-icon-wrapper icon-circle-45 icon-circle-light-green">
                      <Icon
                        icon="icon-home"
                        size={20}
                        color="#2EC99F"
                        className="nl-icon"
                      />
                    </div>
                  </div>
                </div>
              </a>

              {/* This need to be visibled only on mobile */}
              <div className="d-block d-sm-none">
                <div className="nav-link d-flex align-items-center">
                  <div className="bt-icon-wrapper icon-circle-45 icon-circle-light-green">
                    <DropdownMenuItem
                      overlayClassName="profile-dropdown"
                      placement="bottomRight"
                      menuItems={letColCreatemenuItems}
                    >
                      <Icon
                        icon="icon-pluse"
                        size={10}
                        color="#2EC99F"
                        className="nl-icon"
                      />
                    </DropdownMenuItem>
                  </div>
                </div>
              </div>

              {/*
              Sidebar only - "nav-link-btn" this class need to be added to the "nav-link" div
              (if there's no title with icon this class should be removed).
              Icon Circle Color - "icon-circle-light-green", "icon-circle-green", "icon-circle-blue", "icon-circle-yellow".
              Icon Size also need be changed according to the icon type.
              Icon colors - white - #ffffff, Green - #2EC99F
              Title and Description - "bt-text-wrapper" this div need to be visibled only if there is title and description.
              Badge - If there's count with icon this need to be visibled.
            */}
              <div className="nav-link d-flex align-items-center">
                {/* Badge - Count Section for desktop */}
                <div className="d-none d-sm-block">
                  {notificationCount > 0 ? (
                    <div className="icon-badge">
                      {notificationCount > 100 ? '99+' : notificationCount}
                    </div>
                  ) : null}
                </div>

                {/* Badge - Count Section for mobile only */}
                <div className="icon-badge d-block d-sm-none" />

                {/* Icon section */}
                <div
                  className="bt-icon-wrapper icon-circle-45 icon-circle-light-green"
                  onClick={showDrawer}
                  role="button"
                >
                  <Icon
                    icon="icon-notification"
                    size={20}
                    color="#2EC99F"
                    className="nl-icon"
                  />
                </div>

                {/* Title section */}
                {/* <div className="bt-text-wrapper">
                <div className="bt-title">Diary Entry</div>
                <div className="bt-description">Share an entry on Timeline.</div>
              </div> */}
              </div>

              {/* This need to be visibled only on desktop */}
              <div className="d-none d-sm-block">
                <DropdownMenuItem
                  overlayClassName="profile-dropdown"
                  placement="bottomRight"
                  menuItems={menuWebItems}
                >
                  <UserAvatar
                    size={53}
                    shape="circle"
                    src={
                      !isNil(
                        JSON.parse(getSingleData('current-user')).profile_image
                      )
                        ? JSON.parse(getSingleData('current-user'))
                            .profile_image
                        : avatar
                    }
                    style={{ marginLeft: 10 }}
                  />
                </DropdownMenuItem>
              </div>

              {/* This need to be visibled only on mobile */}
              <div className="d-block d-sm-none">
                <DropdownMenuItem
                  overlayClassName="profile-dropdown"
                  placement="bottomRight"
                  menuItems={menuMobileItems}
                >
                  <UserAvatar
                    size={53}
                    shape="circle"
                    src={
                      !isNil(
                        JSON.parse(getSingleData('current-user')).profile_image
                      )
                        ? JSON.parse(getSingleData('current-user'))
                            .profile_image
                        : avatar
                    }
                    style={{ marginLeft: 10 }}
                  />
                </DropdownMenuItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Header };
