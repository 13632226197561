import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { useDispatch, useSelector } from 'react-redux';

import isNil from 'lodash/isNil';
import Icon from '../../icomoon/icon';
import { PopupModal } from '../common/popupModal/PopupModal';
import { AddChildFrom } from '../home/addChildForm/AddChildForm';
import { AddChildPicture } from '../home/addChildPicture/AddChildPicture';
import { AddOtherHalf } from '../home/addOtherHalf/AddOtherHalf';
import { LanguageHelper } from '../../../helpers/languageHelpers/En';
import { addAUserChildSuccess } from '../../../features/userChildren/actions';

const AddChildCard = props => {
  const dispatch = useDispatch();
  const [addChildModalVisible, setVisible] = useState(false);
  const [titleAndDescription, setTitleAndDescription] = useState({
    popUpTile: LanguageHelper.ADD_CHILD_HEADER,
    popUpDescription: LanguageHelper.ADD_CHILD_DESCRIPTION
  });
  const [stage, setStage] = useState(0);
  const [initialData, setData] = useState({
    childId: null,
    child: null
  });

  const setAddChildModalVisible = param => {
    setVisible(param);
    setStage(0);
    if (!isNil(initialData.child)) {
      dispatch(addAUserChildSuccess(initialData.child));
    }
  };
  const goBack = param => {
    if (param >= 0) {
      setStage(param);
      if (param === 2) {
        setTitleAndDescription({
          popUpTile: LanguageHelper.OTHER_HALF_HEADER,
          popUpDescription: LanguageHelper.OTHER_HALF_DESCRIPTION
        });
      } else {
        setTitleAndDescription({
          popUpTile: LanguageHelper.ADD_CHILD_HEADER,
          popUpDescription: LanguageHelper.ADD_CHILD_DESCRIPTION
        });
      }
    }
  };

  const setNewData = (id, child) => {
    setData({
      childId: id,
      child
    });
  };
  let compt = null;
  if (stage === 0) {
    compt = (
      <AddChildFrom
        stage={stage}
        setNewStage={goBack}
        setNewData={setNewData}
        key={uuid()}
      />
    );
  } else if (stage === 1) {
    compt = (
      <AddChildPicture
        stage={stage}
        data={initialData}
        setNewStage={goBack}
        setNewData={setNewData}
        key={uuid()}
      />
    );
  } else if (stage === 2) {
    compt = (
      <AddOtherHalf
        stage={stage}
        data={initialData}
        setNewStage={goBack}
        onCancel={setAddChildModalVisible}
        key={uuid()}
      />
    );
  }
  return (
    <>
      <div className="col-md-6 col-lg-4">
        <div
          className="dash-card dash-card-add-bg d-flex align-items-center justify-content-start justify-content-sm-center"
          onClick={() => setAddChildModalVisible(true)}
          role="button"
        >
          <div className="d-flex align-items-center flex-row flex-sm-column">
            <div className="bt-icon-wrapper icon-circle-100 icon-circle-light-green">
              <Icon
                icon="icon-pluse"
                size={30}
                color="#2EC99F"
                className="nl-icon"
              />
            </div>
            <div className="dc-text"> {LanguageHelper.ADD_CHILD_HEADER}</div>
          </div>
        </div>
      </div>

      {/* Add Child Modal */}
      <PopupModal
        closable={false}
        centered
        footer={null}
        visible={addChildModalVisible}
        onOk={() => setAddChildModalVisible(false)}
        onCancel={() => setAddChildModalVisible(false)}
        onGoback={goBack}
        backBtn
        closeBtn
        modalStyle="default-modal"
        popupTitle={titleAndDescription.popUpTile}
        popupDescription={titleAndDescription.popUpDescription}
        stage={stage}
        // elementKey={uuid()}
      >
        {compt}
      </PopupModal>
    </>
  );
};

export { AddChildCard };
