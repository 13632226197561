import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import isNil from 'lodash/isNil';

import { awsGetASingleImage } from '../../api/signUrl/AWSSignUrl';
import avatar from '../../assets/images/df-profile.jpg';
import { getAvatarImageListSuccess } from '../../features/avatarImages/actions';

const UserAvatar = props => {
  const { size, shape, src, style } = props;
  const dispatch = useDispatch();
  const [avatarImage, setAvatarImage] = useState({ imgSrc: avatar });

  const { getAvatarImageSuccess, isLoading } = useSelector(
    state => state.avatarImages
  );

  useEffect(() => {
    let isMounted = true;
    if (src !== null) {
      if (
        src !== null &&
        !isNil(find(getAvatarImageSuccess.data, ['key', src.key]))
      ) {
        setAvatarImage({
          imgSrc: find(getAvatarImageSuccess.data, ['key', src.key]).url
        });
      } else {
        awsGetASingleImage({
          files: [
            {
              key: src.key
            }
          ],
          location: 'profile-images',
          size: 'small'
        })
          .then(response => {
            if (response.data[0]) {
              if (isMounted) {
                setAvatarImage({ imgSrc: response.data[0].url });
                dispatch(
                  getAvatarImageListSuccess(response.data[0].url, src.key)
                );
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
        return () => {
          isMounted = false;
        };
      }
    }
  }, [getAvatarImageSuccess.data]);

  return (
    <div>
      <Avatar
        size={size}
        shape={shape}
        src={avatarImage.imgSrc}
        style={style}
      />
    </div>
  );
};

UserAvatar.defaultProps = {
  style: '',
  src: { imgSrc: avatar }
};

UserAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  shape: PropTypes.string.isRequired,
  src: PropTypes.object,
  style: PropTypes.string
};
export { UserAvatar };
