/* eslint-disable no-useless-catch */
import * as ApiConnector from '../ApiConnector';
import { KidsAppHelper, KidsAppHelperPost } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const userSignUp = async payload => {
  try {
    const { data } = await ApiConnector.post(KidsAppHelper.signupUrl, payload);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const userEmailVerify = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.verifyEmailUrl,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const userEmailVerifyResend = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.resendVerifyEmailUrl,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const userDetailsUpdate = async (payload, id) => {
  try {
    const { data } = await ApiConnector.patch(
      `${KidsAppHelper.userUpdateUrl}/${id}`,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const userProfileUpload = async id => {
  try {
    const { data } = await ApiConnector.post(
      `${KidsAppHelper.profileUploadUrl}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const userProfileUploadAWS = async (url, payload) => {
  console.log(url, payload);
  const config = {
    headers: {
      put: {
        'Content-Type': 'application/octet-stream'
      }
    }
  };
  try {
    const { data } = await ApiConnector.putWithoutAuth(url, payload, config);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const getUserProfile = async id => {
  try {
    const { data } = await ApiConnector.get(
      `${KidsAppHelper.profileUploadUrl}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const getAllCountries = async () => {
  try {
    const { data } = await ApiConnector.get(KidsAppHelper.countries);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const statesByCountryId = async id => {
  try {
    const params = new URLSearchParams([['countryId', id]]);
    const { data } = await ApiConnector.get(KidsAppHelper.states, { params });
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const citiesByStateId = async id => {
  try {
    const params = new URLSearchParams([['stateId', id]]);
    const { data } = await ApiConnector.get(KidsAppHelper.cities, { params });
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const getMyChild = async id => {
  try {
    const { data } = await ApiConnector.get(
      `${KidsAppHelperPost.myChild}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
