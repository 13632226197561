import React from 'react';
import moment from 'moment';
import isNil from 'lodash/isNil';

import { UserAvatar } from '../../UserAvatar';
import receiveInvitaionIcon from '../../../../assets/images/dasboard/cl-recive-invitation.svg';

const ReceiveInvitationNotificationContent = props => {
  const { data } = props;
  const profileImage = {
    key: data.meta.from_profile_image
  };
  return (
    <>
      {/* Notification - If there is unread notifications "notify-unread" this class need to be added to "notify-message-wrapper" div */}
      <div
        className={
          !data.seen
            ? 'notify-message-wrapper d-flex notification-unseen'
            : 'notify-message-wrapper d-flex'
        }
      >
        <UserAvatar
          size={53}
          shape="circle"
          src={profileImage}
          style={{ marginRight: 15 }}
        />
        <div className="nm-right">
          <div className="d-flex align-items-center justify-content-between">
            <div className="nm-name">
              {!isNil(data.meta.from_name) && data.meta.from_name}
            </div>
            <div className="nm-date">
              {moment
                .utc(data.timeStamp)
                .local()
                .fromNow()}
            </div>
          </div>
          <div className="nm-subtitle d-flex">
            {/* Icons - Only for desktop */}
            <img
              className="d-none d-sm-block"
              src={receiveInvitaionIcon}
              alt="icon"
            />
            <span>
              You have been invited to join as {data.meta.child_name} &apos;s{' '}
              {data.meta.relationship}
            </span>
          </div>
          {/* This section need to be visibled with the quote and comment */}
          <div className="nm-description">
            {/* You have been invited to join as {data.meta.child_name} {data.meta.relationship} */}
          </div>
        </div>
      </div>
    </>
  );
};

export { ReceiveInvitationNotificationContent };
