import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { CustomInputFields, DropDownField } from '../../../common/commonFields';
import {
  emailYupSchema,
  phoneNumberYupSchema
} from '../../../common/commonFields/formValidationSchema/FormValidationSchema';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { sendInvitation } from '../../../../api/invitation/InvitationApi';
import { getSingleData } from '../../../../config/localStorage';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';

const validationSchema = Yup.object().shape({
  email: emailYupSchema
  // phoneNumber: phoneNumberYupSchema
});

const AddOtherHalf = props => {
  const { data, onCancel, setNewStage } = props;
  let relationshipList = [];
  const [mobileVlaue, setMobileVlaue] = useState(null);
  const [initialError, setError] = useState(false);
  if (getSingleData('relationship') === 'father') {
    relationshipList = [
      { value: 'mother', label: 'Mother', key: 'mother' },
      { value: 'guardian', label: 'Guardian', key: 'guardian' }
    ];
  } else if (getSingleData('relationship') === 'mother') {
    relationshipList = [
      { value: 'father', label: 'Father', key: 'father' },
      { value: 'guardian', label: 'Guardian', key: 'guardian' }
    ];
  } else {
    relationshipList = [
      { value: 'father', label: 'Father', key: 'father' },
      { value: 'mother', label: 'Mother', key: 'mother' },
      { value: 'guardian', label: 'Guardian', key: 'guardian' }
    ];
  }

  const submitData = (values, { setSubmitting }) => {
    if (mobileVlaue !== null) {
      if (mobileVlaue.length < 6) {
        setError('Phone number length must be at least 7 characters long');
      } else {
        const partnerInvitation = {
          from: JSON.parse(getSingleData('current-user'))._id,
          to_email: values.email,
          to_mobile: `+${mobileVlaue}`,
          invitation_type: 'partner',
          child_ids: [data.childId],
          relationship: values.relationship
        };
        sendInvitation(partnerInvitation)
          .then(response => {
            if (response.success) {
              ToastrSerivce({ type: 'success', message: response.message });
              setSubmitting(true);
              setNewStage(0);
              onCancel(false);
            } else {
              ToastrSerivce({ type: 'error', message: response.message });
            }
          })
          .catch(error => {
            console.log(error);
            ToastrSerivce({ type: 'error', message: error.message });
          });
      }
    } else {
      setError('Please enter a valid Phone Number ex: +177700000');
    }
  };

  const handleChangeNumber = value => {
    setMobileVlaue(value);
    if (value.length < 7) {
      setError('Phone number length must be at least 7 characters long');
    } else {
      setError(false);
    }
  };
  const skipAddPartner = () => {
    setNewStage(0);
    onCancel(false);
  };

  return (
    <>
      <Formik
        initialValues={{ email: '', phoneNumber: '', relationship: 'father' }}
        validationSchema={validationSchema}
        onSubmit={submitData}
      >
        {({ handleSubmit, handleChange, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <CustomInputFields
              name="email"
              type="email"
              label="Email *"
              placeholder="Partner's Email"
              autoComplete="off"
            />

            <label>Phone Number *</label>
            <PhoneInput
              country="us"
              enableSearch
              placeholder="Partner's Phone Number"
              value={mobileVlaue}
              onChange={e => handleChangeNumber(e)}
            />
            {initialError ? (
              <div className="input-error">{initialError}</div>
            ) : null}

            <DropDownField
              id="relationship"
              name="relationship"
              placeholder="Relationship *"
              label="Relationship"
              options={relationshipList}
              onChange={handleChange}
            />
            <button
              className="button primary-btn-green"
              type="submit"
              disabled={isSubmitting}
            >
              {LanguageHelper.START_BTN_LABLE}
            </button>
            <div className="skip">
              <button className="btn-a" onClick={() => skipAddPartner()}>
                Skip adding partner
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

AddOtherHalf.propTypes = {
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  setNewStage: PropTypes.func.isRequired
};

export { AddOtherHalf };
