import React, { useState } from 'react';

import Icon from '../../../../icomoon/icon';

const EditMenuItem = props => {
  return (
    <div className="d-flex align-items-center">
      <Icon icon="icon-edit" size={25} color="#2EC99F" className="nl-icon" />
      <a onClick={e => e.preventDefault()} role="button">
        Edit
      </a>
    </div>
  );
};

export { EditMenuItem };
