import React from 'react';
import { Tabs } from 'antd';

import { AddChildCard } from '../../../dashboard/cards/AddChildCard';
import { ChildCard } from '../../../dashboard/cards/ChildCard';

const RightLayout = props => {
  return <div className="dash-right-col">{props.children}</div>;
};

export { RightLayout };
