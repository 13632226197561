import React from 'react';
import { Menu, Dropdown } from 'antd';

const DropdownMenuItem = props => {
  const { placement, overlayClassName, children, menuItems } = props;
  const menu = (
    <Menu className="dash-menu profile-menu">
      {menuItems.map((data, index) =>
        data === 'divider' ? (
          <Menu.Divider key={index} style={{ margin: '15px 0' }} />
        ) : (
          <Menu.Item key={index}>{data}</Menu.Item>
        )
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName={overlayClassName}
      overlay={menu}
      trigger={['click']}
      placement={placement}
    >
      <a
        className="ant-dropdown-link"
        onClick={e => e.preventDefault()}
        role="button"
      >
        {children}
      </a>
    </Dropdown>
  );
};

export { DropdownMenuItem };
