/* eslint-disable indent */
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';

import { rootReducer } from './rootReducer';
// optional injected dependencies for any logic
export const dependencies = {
  history: createBrowserHistory(),
  // isTrackingInit: false,
  // trackingPath: '',
  // Here >> add anything you need to have available in your logic
  _dispatch: null
};

let middleware = [];

if (process.env.REACT_APP_ENV === 'local') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

// using compose to allow for applyMiddleware, just add it in
const enhancer =
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : applyMiddleware(...middleware);

export const store = createStore(rootReducer(dependencies.history), enhancer);
dependencies._dispatch = store.dispatch;
