import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import size from 'lodash/size';

import findIndex from 'lodash/findIndex';
import isNil from 'lodash/isNil';
import {
  getUserChildren,
  getOtherChildren
} from '../../../../api/child/ChildApi';
import { getSingleData } from '../../../../config/localStorage';
import { UserAvatar } from '../../UserAvatar';

const ChildrenAvatarLoop = props => {
  const { onLoadSelectedChidData, editStateChildren, edit } = props;

  let childArray = [];
  let userChildren = {};
  let otherChildren = {};

  const [childrenData, setChildrenData] = useState([]);
  const [otherChildrenData, setOtherChildrenData] = useState([]);
  const [allChildrenData, setAllChildrenData] = useState([]);

  const dispatch = useDispatch();

  const { getUserChildrenListSuccess, isUserChildrenLoading } = useSelector(
    state => state.userChildren
  );

  const { getOtherChildrenListSuccess, isOtherChildrenLoading } = useSelector(
    state => state.otherChildren
  );

  useEffect(() => {
    if (getUserChildrenListSuccess.data) {
      userChildren = getUserChildrenListSuccess.data.children;
      setChildrenData(userChildren);
    } else {
      dispatch(getUserChildren(JSON.parse(getSingleData('current-user'))._id));
    }
  }, [getUserChildrenListSuccess.data]);

  useEffect(() => {
    if (getOtherChildrenListSuccess.data) {
      otherChildren = getOtherChildrenListSuccess.data.children;
      setOtherChildrenData(otherChildren);
    } else {
      dispatch(getOtherChildren(JSON.parse(getSingleData('current-user'))._id));
    }
  }, [getOtherChildrenListSuccess.data]);

  useEffect(() => {
    if (childrenData.length > 0 || otherChildrenData.length > 0) {
      let allChildren = childrenData;
      allChildren = allChildren.concat(otherChildrenData);
      if (size(editStateChildren) === 0) {
        for (let i = 0; i < size(allChildren); i++) {
          allChildren[i].select = false;
          if (i === size(allChildren) - 1) {
            setAllChildrenData(allChildren);
          }
        }
      }
      if (edit) {
        for (let i = 0; i < size(allChildren); i++) {
          for (let x = 0; x < size(editStateChildren); x++) {
            if (allChildren[i].id === editStateChildren[x]) {
              allChildren[i].select = true;
            }
          }
          if (i === size(allChildren) - 1) {
            setAllChildrenData(allChildren);
          }
        }
      } else {
        setAllChildrenData(allChildren);
      }
    }
  }, [childrenData.length > 0, otherChildrenData, props]);

  const imageClick = id => {
    const allChildren = allChildrenData;
    childArray = [];
    if (findIndex(allChildren, { id }) > -1) {
      if (!allChildren[findIndex(allChildren, { id })].select) {
        allChildren[findIndex(allChildren, { id })].select = true;
        let x = 0;
        for (let i = 0; i < size(allChildren); i++) {
          if (allChildren[i].select) {
            childArray.push(allChildren[i].id);
            x++;
          }
          if (i === size(allChildren) - 1) {
            onLoadSelectedChidData(childArray);
            setAllChildrenData(allChildren);
          }
        }
      } else {
        allChildren[findIndex(allChildren, { id })].select = false;
        let x = 0;
        for (let i = 0; i < size(allChildren); i++) {
          if (allChildren[i].select) {
            childArray[x] = allChildren[i].id;
            x++;
          }
          if (i === size(allChildren) - 1) {
            onLoadSelectedChidData(childArray);
            setAllChildrenData(allChildren);
          }
        }
      }
    }
  };

  return (
    <>
      {allChildrenData.map((childData, index) => (
        <div
          key={index}
          onClick={() => imageClick(childData.id)}
          role="button"
          style={{ display: 'inline-block' }}
          className={childData.select ? 'avatar-select' : null}
        >
          <UserAvatar
            key={index}
            size={30}
            shape="circle"
            src={childData.child_image}
          />
        </div>
      ))}
    </>
  );
};
ChildrenAvatarLoop.defaultProps = {
  editStateChildren: []
};
ChildrenAvatarLoop.propTypes = {
  onLoadSelectedChidData: PropTypes.func.isRequired,
  editStateChildren: PropTypes.array
};

export { ChildrenAvatarLoop };
