import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isNil from 'lodash/isNil';

import { useDispatch, useSelector } from 'react-redux';
import { childImageUpload, getAChild } from '../../../../api/child/ChildApi';
import { dateConvert } from '../../../../core/common/index';
import { UserAvatar } from '../../UserAvatar';
import Icon from '../../../icomoon/icon';
import { getUser } from '../../../../api/userLogin/userLogin';
import { FileInputField } from '../../../common/commonFields';
import profile from '../../../../assets/images/df-profile.jpg';
import { getAvatarImageListSuccess } from '../../../../features/avatarImages/actions';
import { awsGetASingleImage } from '../../../../api/signUrl/AWSSignUrl';
import {
  userProfileUpload,
  userProfileUploadAWS
} from '../../../../api/userRegistration/UserRegistrationApi';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { getSingleData } from '../../../../config/localStorage';

const ProfileCard = props => {
  const { id, type, myChild } = props;
  const dispatch = useDispatch();
  const [avatarImage, setAvatarImage] = useState({ imgSrc: profile });

  const { getAvatarImageSuccess, isLoading } = useSelector(
    state => state.avatarImages
  );

  const [initialProfileObj, setProfileObj] = useState({
    file: '',
    imagePreviewUrl: profile
  });
  const [initialError, setError] = useState(false);
  const FILE_SIZE = 10 * 1024 * 1024;
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  const [responseData, setResponseData] = useState({});
  const [responseImage, setResponseImage] = useState({});

  useEffect(() => {
    if (type === 'child') {
      getAChild(id)
        .then(response => {
          const timeFormDOB = dateConvert(response.data.child.dob);
          setResponseData(response.data.child);
          setResponseImage(response.data.child.child_image);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      getUser(id === null ? JSON.parse(getSingleData('current-user'))._id : id)
        .then(response => {
          setResponseData(response.data.users);
          setResponseImage(response.data.users.profile_image);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (!isNil(responseImage.key)) {
      if (
        !isNil(responseImage.key) &&
        !isNil(find(getAvatarImageSuccess.data, ['key', responseImage.key]))
      ) {
        setAvatarImage({
          imgSrc: find(getAvatarImageSuccess.data, ['key', responseImage.key])
            .url
        });
      } else {
        awsGetASingleImage({
          files: [
            {
              key: responseImage.key
            }
          ],
          location: 'profile-images',
          size: 'small'
        })
          .then(response => {
            if (response.data[0]) {
              setAvatarImage({ imgSrc: response.data[0].url });
              dispatch(
                getAvatarImageListSuccess(
                  response.data[0].url,
                  responseImage.key
                )
              );
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }, [responseImage]);

  const photoUpload = e => {
    e.preventDefault();
    const reader = new FileReader();
    let file = e.target.files[0];
    if (!SUPPORTED_FORMATS.includes(file.type)) {
      setError({ message: 'Only JPG/JPGE and PNG allowed' });
      ToastrSerivce({
        type: 'error',
        message: 'Only JPG/JPGE and PNG allowed'
      });
      file = null;
    } else if (file.size > FILE_SIZE) {
      setError({ message: 'Image size is too large to upload' });
      ToastrSerivce({
        type: 'error',
        message: 'Image size is too large to upload'
      });
      file = null;
    } else {
      reader.onloadend = () => {
        setProfileObj({
          file,
          imagePreviewUrl: reader.result
        });
        setAvatarImage({
          imgSrc: reader.result
        });
      };
      if (type === 'child') {
        childImageUpload(id).then(
          response => {
            if (response.success) {
              userProfileUploadAWS(response.data.url, file).then(
                res => {
                  ToastrSerivce({
                    type: 'success',
                    message: 'profile image upload success'
                  });
                },
                error => {
                  console.error(error);
                  ToastrSerivce({ type: 'error', message: error.message });
                }
              );
            }
          },
          error => {
            ToastrSerivce({ type: 'error', message: error.message });
          }
        );
      } else {
        userProfileUpload(id).then(
          response => {
            if (response.success) {
              userProfileUploadAWS(response.data.url, file).then(
                res => {
                  ToastrSerivce({
                    type: 'success',
                    message: 'profile image upload success'
                  });
                },
                error => {
                  console.error(error);
                  ToastrSerivce({ type: 'error', message: error.message });
                }
              );
            }
          },
          error => {
            ToastrSerivce({ type: 'error', message: error.message });
          }
        );
      }
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="profile-card-wrapper">
      {/* Make this div clickable */}
      <div className="pc-avatar-wrapper">
        {myChild ? (
          <FileInputField
            name="image"
            type="file"
            label="profile image"
            filetype="image"
            onChange={photoUpload}
            src={avatarImage.imgSrc}
            multiple={false}
            location="profileCard"
          />
        ) : null}
        {type === 'user' ? (
          <FileInputField
            name="image"
            type="file"
            label="profile image"
            filetype="image"
            onChange={photoUpload}
            src={avatarImage.imgSrc}
            multiple={false}
            location="profileCard"
          />
        ) : null}
        {!myChild && responseData.child_image !== undefined && (
          <UserAvatar
            size={130}
            shape="circle"
            src={responseData.child_image}
            style={{ border: '5px solid #fff' }}
          />
        )}
        {/* Profile pic upload icon */}
        {/* <div className="nav-link d-flex align-items-center pc-upload-btn">
          <div className="bt-icon-wrapper icon-circle-35 icon-circle-light-green">
            <Icon
              icon="icon-add-photo"
              size={18}
              color="#2EC99F"
              className="nl-icon"
            />
          </div>
        </div> */}
      </div>

      <div className="pc-details-wrapper">
        <div className="pc-name">
          {responseData.first_name} {responseData.last_name}
        </div>
        <div className="pc-dob">{dateConvert(responseData.dob)}</div>
      </div>
    </div>
  );
};

ProfileCard.defaultProps = {
  myChild: false
};

ProfileCard.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  myChild: PropTypes.bool
};

export { ProfileCard };
