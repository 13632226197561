import React, { useState, useEffect } from 'react';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

function SingleOtpInput(props) {
  const {
    focus,
    shouldAutoFocus,
    isLastChild,
    isDisabled,
    disabledStyle,
    isInputNum,
    value,
    ...rest
  } = props;

  let input;

  // Focus on first render
  // Only when shouldAutoFocus is true
  useEffect(() => {
    if (input && focus && shouldAutoFocus) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    if (input && focus) {
      input.focus();
      input.select();
    }
  }, [focus]);

  const numValueLimits = isInputNum ? { min: 0, max: 9 } : {};

  return (
    <input
      className="form-control input-single"
      type={isInputNum ? 'number' : 'tel'}
      {...numValueLimits}
      maxLength={1}
      ref={ref => {
        input = ref;
      }}
      disabled={isDisabled}
      value={value || ''}
      {...rest}
    />
  );
}

function OtpInputField(props) {
  const {
    numInputs,
    isDisabled,
    shouldAutoFocus,
    onChange,
    isInputNum,
    value,
    disabledStyle
  } = props;
  const [activeInput, setActiveInput] = useState(0);

  const getOtpValue = () => (value ? value.toString().split('') : []);

  // Helper to return OTP from input
  const handleOtpChange = otp => {
    const otpValue = otp.join('');
    onChange(isInputNum ? Number(otpValue) : otpValue);
  };

  // Focus on input by index
  const focusInput = input => {
    setActiveInput(Math.max(Math.min(numInputs - 1, input), 0));
  };

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  // Focus on previous input
  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  const changeCodeAtFocus = focusedInputValue => {
    const otp = getOtpValue();
    otp[activeInput] = focusedInputValue[0];

    handleOtpChange(otp);
  };

  // Handle pasted OTP
  const handleOnPaste = e => {
    e.preventDefault();
    const otp = getOtpValue();

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift();
      }
    }
    handleOtpChange(otp);
  };

  const handleOnChange = e => {
    changeCodeAtFocus(e.currentTarget.value);
    focusNextInput();
  };

  // Handle cases of backspace, delete, left arrow, right arrow
  const handleOnKeyDown = e => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput();
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === ' ' ||
      e.key === 'Spacebar'
    ) {
      e.preventDefault();
    }
  };

  const checkLength = e => {
    if (e.currentTarget.value.length > 1) {
      e.preventDefault();
      focusNextInput();
    }
  };

  const handleFocus = i => e => {
    setActiveInput(i);
    e.currentTarget.select();
  };

  const handleBlur = () => setActiveInput(-1);

  const renderInputs = () => {
    const otp = getOtpValue();
    const inputs = [];

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          key={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onInput={checkLength}
          onPaste={handleOnPaste}
          onFocus={handleFocus(i)}
          onBlur={handleBlur}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          shouldAutoFocus={shouldAutoFocus}
          isInputNum={isInputNum}
        />
      );
    }

    return inputs;
  };

  return <div style={{ display: 'flex' }}>{renderInputs()}</div>;
}

export { OtpInputField };
