import store from 'store2';

// store({key: data, key2: data})
export const setAllData = storeObject => {
  store.setAll(storeObject);
};

// store(key, data)
export const setSingleData = (key, value) => {
  store.set(key, value);
};

// store()
export const getAllData = () => {
  return store.getAll();
};

// store(key)
export const getSingleData = props => {
  return store.get(props);
};

// store(false)
export const clearData = () => {
  store.clear();
};

// returns true or false
export const hasData = key => {
  return store.has(key);
};

// removes key and its data, then returns the data or alt, if none
export const removeSingleData = key => {
  store.remove(key);
};

// concats, merges, or adds new value into existing one
export const addNewValue = (key, value) => {
  store.add(key, value);
};

// number of keys, not length of data
export const numberOfKeys = () => {
  store.size();
};

// clears *ALL* areas (but still namespace sensitive)
export const clearAllData = () => {
  store.clearAll();
};

// Other store functions
// store.transact(key, fn[, alt]); // === store(key, fn[, alt]);
// store.each(fn[, fill]); // === store(fn); optional call arg will be 3rd fn arg (e.g. for gathering values)
// store.keys([fillList]);  // clears *ALL* areas (but still namespace sensitive)

// const localStorageConfig = {
//   setAllData,
//   setSingleData,
//   getAllData,
//   getSingleData,
//   clearData,
//   hasData,
//   removeSingleData,
//   addNewValue,
//   numberOfKeys,
//   clearAllData
// };

// export default localStorageConfig;
