import React from 'react';

const InputTypeErrors = props => {
  const errorData = props;
  if (errorData.errors.touched && errorData.errors.error) {
    return <div className="input-error">{errorData.errors.error}</div>;
  }
  return null;
};

export default InputTypeErrors;
