import * as ApiConnector from '../ApiConnector';
import { KidsAppHelperPost } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const addEvent = async (payload, edit, id) => {
  try {
    if (edit) {
      const { data } = await ApiConnector.put(
        `${KidsAppHelperPost.event}/${id}`,
        payload
      );
      return data;
    }
    const { data } = await ApiConnector.post(KidsAppHelperPost.event, payload);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const getEvent = async id => {
  try {
    const { data } = await ApiConnector.get(`${KidsAppHelperPost.event}/${id}`);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const deleteEvent = async id => {
  try {
    const { data } = await ApiConnector.del(
      `${KidsAppHelperPost.event}/${id}`,
      { id }
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
