import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider } from 'antd';

import Icon from '../../../icomoon/icon';

const PopupModal = props => {
  const {
    centered,
    closable,
    footer,
    visible,
    onOk,
    backBtn,
    closeBtn,
    children,
    modalStyle,
    popupTitle,
    popupDescription,
    stage,
    elementKey
  } = props;

  const handleClose = value => {
    props.onCancel(value);
  };
  const goback = () => {
    if (stage && stage > 0) {
      props.onGoback(stage - 1);
    }
  };
  return (
    <Modal
      // title="Vertically centered modal dialog"
      className={modalStyle}
      centered={centered}
      closable={closable}
      footer={footer}
      // bodyStyle={{ padding: '40px', borderRadius: '8px' }}
      maskStyle={{
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        zIndex: '99999'
      }}
      visible={visible}
      onOk={() => onOk}
      key={elementKey}
    >
      <div className="mod-header d-flex align-items-center justify-content-between">
        <div className="mh-left">
          {/* Back Button - This need to be visibled only need to go step back */}
          {backBtn ? (
            <div
              className="bt-icon-wrapper icon-circle-45"
              onClick={() => goback()}
              role="button"
            >
              <Icon
                icon="icon-back"
                size={15}
                color="#7C7C7C"
                className="nl-icon"
              />
            </div>
          ) : null}
        </div>
        <div className="mh-center">{popupTitle}</div>
        <div className="mh-right">
          {closeBtn ? (
            <div
              className="bt-icon-wrapper icon-circle-45"
              onClick={() => handleClose(true)}
              role="button"
            >
              <Icon
                icon="icon-close"
                size={15}
                color="#7C7C7C"
                className="nl-icon"
              />
            </div>
          ) : null}
        </div>
      </div>
      <Divider
        style={{
          backgroundColor: '#EAEAEA',
          marginTop: '25px',
          marginBottom: '30px'
        }}
      />
      <p className="description-send-invitation">{popupDescription}</p>
      {children}
    </Modal>
  );
};
PopupModal.defaultProps = {
  footer: null,
  modalStyle: null,
  popupDescription: '',
  popupTitle: '',
  stage: null,
  onGoback: null,
  visible: false,
  elementKey: null
};

PopupModal.propTypes = {
  centered: PropTypes.bool.isRequired,
  visible: PropTypes.bool,
  closable: PropTypes.bool.isRequired,
  footer: PropTypes.string,
  modalStyle: PropTypes.string,
  popupDescription: PropTypes.string,
  popupTitle: PropTypes.string,
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  backBtn: PropTypes.bool.isRequired,
  closeBtn: PropTypes.bool.isRequired,
  stage: PropTypes.number,
  onGoback: PropTypes.func,
  elementKey: PropTypes.string
};
export { PopupModal };
