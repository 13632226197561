import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { UserAvatar } from '../../UserAvatar';
import { acceptInvitation } from '../../../../api/invitation/InvitationApi';
import { getSingleData } from '../../../../config/localStorage';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';
import {
  getAChild,
  getOtherChildren,
  getUserChildren
} from '../../../../api/child/ChildApi';
import hart from '../../../../assets/images/ai-animation.png';

const AcceptInvitationForm = props => {
  const { data, onCancel } = props;
  const dispatch = useDispatch();
  // let avatarDiv = '';

  const [invitationChild, setInvitationChild] = useState({ child: null });
  const [invitationAllChildren, setInvitationAllChildren] = useState([]);
  const [relationship, setRelationship] = useState(null);
  let userAvatarNew = null;

  useEffect(() => {
    if (!isEmpty(data)) {
      setRelationship(data.response.relationship);
      getAChild(data.response.child_ids[0])
        .then(response => {
          userAvatarNew = response.data.child;
          if (response.data.child) {
            setInvitationChild({
              child: response.data.child
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
      const allChildren = [];
      data.response.child_ids.map((child, index) =>
        getAChild(child)
          .then(response => {
            if (response.data.child) {
              allChildren.push(response.data.child);
              if (index === size(data.response.child_ids) - 1) {
                setInvitationAllChildren(allChildren);
              }
            }
          })
          .catch(error => {
            console.log(error);
          })
      );
    }
  }, [data]);

  const submitData = () => {
    acceptInvitation({ invitation_id: data.response._id })
      .then(response => {
        if (response.success) {
          ToastrSerivce({ type: 'success', message: response.message });
          // dispatch(
          //   getOtherChildren(JSON.parse(getSingleData('current-user'))._id)
          // );
          // dispatch(
          //   getUserChildren(JSON.parse(getSingleData('current-user'))._id)
          // );
          onCancel(false, invitationAllChildren, relationship);
        }
      })
      .catch(error => {
        if (error.message) {
          ToastrSerivce({ type: 'error', message: error.message });
        } else {
          ToastrSerivce({ type: 'error', message: error.error });
        }
      });
  };

  return (
    <div className="accept-invitation-wrapper">
      <div>
        <p className="description-send-invitation">
          You have been invited to{' '}
          {invitationChild.child ? invitationChild.child.first_name : null}’s
          family tree as {!isEmpty(data) ? data.response.relationship : null}
        </p>
        {/* {userAvatarNew} */}
        <div className="ani-avatars-wrapper d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center">
            {invitationChild.child ? (
              <UserAvatar
                size={124}
                shape="circle"
                src={invitationChild.child.child_image}
                style={{ marginRight: 30, border: '1px solid #eaeaea' }}
              />
            ) : null}
            <UserAvatar
              size={124}
              shape="circle"
              src={JSON.parse(getSingleData('current-user')).profile_image}
              style={{ marginLeft: 30, border: '1px solid #eaeaea' }}
            />
          </div>
          <div style={{ marginTop: 18 }}>
            <img src={hart} alt="hart" />
          </div>
        </div>
      </div>
      <button className="button primary-btn-green" onClick={() => submitData()}>
        {LanguageHelper.ACCEPT_INVITATION_BTN_LABLE}
      </button>
    </div>
  );
};

AcceptInvitationForm.propTypes = {
  onCancel: PropTypes.func.isRequired
};
export { AcceptInvitationForm };
