import React from 'react';

import { LoginLayout, WelcomeLayout } from './components/layout';
import { DashboardLayout } from './components/layout/dashboard';

const signUp = React.lazy(() =>
  import('./containers/userRegistration/UserRegistration')
);
const login = React.lazy(() => import('./containers/userLogin/UserLogin'));
const resetPassword = React.lazy(() =>
  import('./containers/resetPassword/ResetPassword')
);
const resetPasswordOtp = React.lazy(() =>
  import('./containers/resetPasswordOtp/ResetPasswordOtp')
);
const newPassword = React.lazy(() =>
  import('./containers/newPassword/NewPassword')
);
const welcome = React.lazy(() => import('./containers/welcome/Welcome'));
const addChild = React.lazy(() => import('./containers/addChild/AddChild'));
const addPicture = React.lazy(() =>
  import('./containers/addPicture/AddPicture')
);
const otherHalf = React.lazy(() => import('./containers/otherHalf/OtherHalf'));
const sendInvitation = React.lazy(() =>
  import('./containers/sendInvitation/SendInvitation')
);
const invitationSent = React.lazy(() =>
  import('./containers/invitationSent/InvitationSent')
);
const acceptInviteOtp = React.lazy(() =>
  import('./containers/acceptInviteOtp/AcceptInviteOtp')
);
const acceptInvite = React.lazy(() =>
  import('./containers/acceptInvite/AcceptInvite')
);
const acceptInviteCongratulations = React.lazy(() =>
  import('./containers/acceptInviteCongratulations/AcceptInviteCongratulations')
);
const home = React.lazy(() => import('./containers/dashboard/home/Home'));
const timeLine = React.lazy(() =>
  import('./containers/dashboard/timeLine/TimeLine')
);
const search = React.lazy(() => import('./containers/dashboard/search/Search'));
const profile = React.lazy(() =>
  import('./containers/dashboard/profile/Profile')
);
const post = React.lazy(() =>
  import('./containers/dashboard/singlePost/SinglePost')
);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: login,
    auth: false,
    layouts: { mainLayout: LoginLayout }
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: resetPassword,
    auth: false,
    layouts: { mainLayout: LoginLayout }
  },
  {
    path: '/sign-up',
    name: 'Registration',
    component: signUp,
    auth: false,
    layouts: { mainLayout: LoginLayout }
  },
  {
    path: '/reset-password-otp',
    name: 'ResetPasswordOtp',
    component: resetPasswordOtp,
    auth: false,
    layouts: { mainLayout: LoginLayout }
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: newPassword,
    auth: false,
    layouts: { mainLayout: LoginLayout }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: welcome,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/add-child',
    name: 'AddChild',
    component: addChild,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/add-picture',
    name: 'AddPicture',
    component: addPicture,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/other-half',
    name: 'OtherHalf',
    component: otherHalf,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/send-invitation',
    name: 'SendInvitation',
    component: sendInvitation,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/invitation-sent',
    name: 'InvitationSent',
    component: invitationSent,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/accept-invite-otp',
    name: 'AcceptInviteOtp',
    component: acceptInviteOtp,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/accept-invite',
    name: 'AcceptInvite',
    component: acceptInvite,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/accept-invite-congratulations',
    name: 'AcceptInviteCongratulations',
    component: acceptInviteCongratulations,
    auth: true,
    layouts: { mainLayout: WelcomeLayout }
  },
  {
    path: '/timeLine/:child_id',
    name: 'Time Line',
    component: timeLine,
    auth: true,
    layouts: { mainLayout: DashboardLayout }
  },
  {
    path: '/search',
    name: 'Search',
    component: search,
    auth: true,
    layouts: { mainLayout: DashboardLayout }
  },
  {
    path: '/profile/:user_id',
    name: 'Profile',
    component: profile,
    auth: true,
    layouts: { mainLayout: DashboardLayout }
  },
  {
    path: '/:type/:post_id',
    name: 'Post',
    component: post,
    auth: true,
    layouts: { mainLayout: DashboardLayout }
  },
  {
    path: '/',
    name: 'Home',
    component: home,
    auth: true,
    layouts: { mainLayout: DashboardLayout }
  }
];

export default routes;
