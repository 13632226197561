import React from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import InputTypeErrors from '../inputTypeErrors/InputTypeErrors';

const CustomTextArea = ({ label, placeholder, ...props }) => {
  const [field, meta] = useField(props);
  let inputClassStyle = 'form-control';
  let divStyle = {
    width: '100%'
  };
  if (props.stage === 1) {
    inputClassStyle = 'verify-input';
    divStyle = {
      width: '25%',
      display: 'inline-flex'
    };
  }
  return (
    <div style={divStyle} className="form-group">
      <label>{label}</label>
      <textarea
        className={inputClassStyle}
        {...field}
        {...props}
        value={field.value || ''}
        placeholder={placeholder}
        autoComplete="off"
      />

      <InputTypeErrors errors={meta} />
    </div>
  );
};
CustomTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export { CustomTextArea };
