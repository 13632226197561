export const LanguageHelper = Object.freeze({
  NEXT_BTN_LABLE: 'Next',
  EMAIL_MOBILE_VERFICATION_HEADER: "Let's Get You Started",
  EMAIL_MOBILE_VERFICATION_DESCRIPTION:
    'Provide an email account and phone number to setup your Moment account with you.',
  PASS_CODE_HEADER: 'One Time Passcode',
  PASS_CODE_DESCRIPTION:
    'Enter the 4 digit One Time Passcode you received via the email address/phone number provided.',
  OTP_DESCRIPTION:
    'Enter the 4 digit One Time Passcode you received via the email address provided.',
  ABOUT_YOU_HEADER: 'A Little About You',
  ABOUT_YOU_DESCRIPTION: 'Your basic information to get you started.',
  SET_PROFILE_IMAGE_HEADER: 'Smile!',
  SET_PROFILE_IMAGE_DESCRIPTION:
    'Add a profile picture to use with your profile.',
  SET_PASSWORD_HEADER: "Let's Secure Your Account",
  SET_PASSWORD_DESCRIPTION: 'Enter a password to secure your account.',
  PASSCODE_NOT_RECEIVE: "Didn't receive the code ?",
  // PASSCODE_NOT_RECEIVE: "Didn't receive? Try checking your Spam folder or ",
  PASSCODE_TOP_TEXT:
    'Invite those close to you and build your family tree with the people who mean the most.',
  LOGIN_BTN_LABLE: 'Login',
  FORGOT_PASSWORD: 'Forgot Password?',
  LOGIN_TOP_TEXT:
    'All your special moments, organized together in one place, to cherish. Forever',
  PROFILE_PIC_TOP_TEXT:
    'Gift your loved ones through Moment, Help them keep track of it, Forever.',
  SIGN_UP_TEXT: 'Don’t you have an account ? ',
  SIGN_UP_LINK: 'Sign Up',
  SIGN_IN_HEADER: 'Sign In',
  RESET_PASSWORD_HEADER: 'Reset Your Password',
  RESET_PASSWORD_TOP_TEXT:
    'Save every photo, event, memo or Moment, Live them later, anytime, anywhere.',
  WELCOME_HEADER_LINE_1: 'Welcome to',
  WELCOME_HEADER_LINE_2: 'Moment!',
  UPDATE_PASSWORD_BTN_LABLE: 'Update Password',
  PASSWORD_HINT:
    'Passwords must contain :<br />1. Between 8-16 characters<br />2. A minimum of 1 upper case letter [A-Z]<br />3. A minimum of 1 numeric character [0-9]',
  WELCOME_DESCRIPTION:
    'Moment is an app designed to help you create a Timeline of your most precious memories. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus mollis mollis nibh eget semper. Morbi porttitor, magna eget porta efficitur, orci nisl rutrum dui. Saving your memories, one moment at a time.',
  TIMELINE_BTN_LABLE: "Start my child's Timeline",
  INVITE_BTN_LABLE: 'I was invited to join Moment',
  ADD_CHILD_HEADER: 'Add A Child',
  ADD_CHILD_DESCRIPTION: "Let's get started by adding some basic info.",
  OTHER_HALF_HEADER: 'The Other Half',
  OTHER_HALF_DESCRIPTION:
    "Add your partner’s email address to invite them to the child's Timeline.",
  START_BTN_LABLE: 'Get started with Moment',
  SEND_INVITE_HEADER: 'Invite Family Members To Build Your Family Tree',
  SEND_INVITE_DESCRIPTION_1:
    'Invite family members by email or by generating a QR code for them to scan.',
  SEND_INVITE_DESCRIPTION_2:
    'Select the children’s profiles you wish to add the member to:',
  GENERATE_QR_BTN_LABLE: 'Generate and share QR code',
  SEND_EMAIL_BTN_LABLE: 'Send Email',
  INVITE_SENT_HEADER: 'Invitation Sent!',
  INVITE_SENT_DESCRIPTION:
    'The invitation has been successfully sent! The invited members will be added to your tree when they accept your request.',
  BACK_TO_MOMENT_BTN_LABLE: 'Back to Moment',
  ACCEPT_INVITE_OTP_HEADING: 'You Have Been Invited To Join A Family Tree',
  ACCEPT_INVITE_OTP_DESCRIPTION: 'Enter the 8-digit invitation code',
  ACCEPT_INVITE_HEADING: 'You Have Been Invited To Join A Family Tree',
  ACCEPT_INVITE_DESCRIPTION:
    'You have been invited to join James David’s family tree as his aunt',
  ACCEPT_INVITATION_BTN_LABLE: 'Accept Invitation',
  ACCEPT_INVITE_CONGRATULATIONS_HEADING: 'Congratulations!',
  // ACCEPT_INVITE_CONGRATULATIONS_DESCRIPTION:
  //   'You have been added to James David’s family tree as his aunt.',
  CONTINUE_TO_MOMENT_BTN_LABLE: 'Continue to Moment',
  CREATE_ALBUM: 'Create Album',
  EDIT_ALBUM: 'Edit Album',
  DELETE_ALBUM: 'Delete Album',
  CREATE_EVENT: 'Create Event',
  EDIT_EVENT: 'Edit Event',
  DELETE_EVENT: 'Delete Event',
  CREATE_DIARY_ENTRY: 'Create Diary Entry',
  EDIT_DIARY_ENTRY: 'Edit Diary Entry',
  DELETE_DIARY_ENTRY: 'Delete Diary Entry',
  ADD_DIARY_ENTRY_BTN_LABEL: 'Add Entry to Moment',
  ADD_ALBUM_BTN_LABEL: 'Add Album to Moment',
  ADD_EVENT_BTN_LABEL: 'Add Event to Moment',
  ALBUM_KIDS_ASSIGN_LABEL: 'These kid(s) are tagged in this album',
  EVENT_KIDS_ASSIGN_LABEL: 'These kid( s ) are tagged in this event',
  DIARY_ENTRY_KID_ASSIGN_LABEL: 'These kid(s) are tagged in this diary entry',
  ALBUM_KID_ASSIGN_LABEL: 'These kid(s) are tagged in this album',
  EVENT_KID_ASSIGN_LABEL: 'These kid(s) are tagged in this event',
  DIARY_ENTRY_KIDS_ASSIGN_LABEL: 'These kid(s) are tagged in this diary entry',
  INVITE_TO_FAMILY_MEMBERS: 'Invite Family Members',
  SAVE_BTN_LABEL: 'save'
});
