import React from 'react';
import PropTypes from 'prop-types';

const CommonMenuItem = props => {
  const { title, description, iconComponent, iconClass, buttonClass } = props;
  return (
    <>
      <div className={buttonClass}>
        {/* Icon section */}
        <div className={iconClass}>{iconComponent}</div>

        {/* Title section */}
        <div className="bt-text-wrapper">
          <div className="bt-title">{title}</div>
          <div className="bt-description">{description}</div>
        </div>
      </div>
    </>
  );
};
CommonMenuItem.defaultProps = {
  description: '',
  buttonClass: '',
  iconClass: '',
  title: ''
};
CommonMenuItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  iconComponent: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  iconClass: PropTypes.string,
  buttonClass: PropTypes.string
};

export { CommonMenuItem };
