import React, { useState } from 'react';

import Icon from '../../../../icomoon/icon';

const UnreadMenuItem = props => {
  return (
    <div className="d-flex align-items-center">
      <a onClick={e => e.preventDefault()} role="button">
        Unread
      </a>
    </div>
  );
};

export { UnreadMenuItem };
