/* eslint-disable no-useless-catch */
import * as ApiConnector from '../ApiConnector';
import { KidsAppHelper, KidsAppHelperPost } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const sendInvitation = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.sendInvitation,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const checkInvitation = async id => {
  try {
    const { data } = await ApiConnector.get(
      `${KidsAppHelper.checkInvitation}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const validateInvitationPasscode = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.validateInvitationPasscode,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const acceptInvitation = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.acceptInvitation,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const removeUserFromFamily = async (childId, payload) => {
  try {
    const { data } = await ApiConnector.del(
      `${KidsAppHelperPost.familyMembers}/${childId}`,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
