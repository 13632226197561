import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { childImageUpload } from '../../../../api/child/ChildApi';
import { userProfileUploadAWS } from '../../../../api/userRegistration/UserRegistrationApi';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { FileInputField } from '../../../common/commonFields';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';
import profile from '../../../../assets/images/df-profile.jpg';
import removeIcon from '../../../../assets/images/icons/remove-red.svg';

const AddChildPicture = props => {
  const { data, setNewStage, setNewData } = props;
  const [initialError, setError] = useState(false);
  const FILE_SIZE = 10 * 1024 * 1024;
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  const [initialProfileObj, setProfileObj] = useState({
    file: '',
    imagePreviewUrl: profile
  });

  const submitData = (values, { setSubmitting }) => {
    if (!initialError) {
      if (initialProfileObj.file === '') {
        setSubmitting(false);
        ToastrSerivce({
          type: 'error',
          message: 'please add a image'
        });
      } else {
        childImageUpload(data.childId).then(
          response => {
            if (response.success) {
              userProfileUploadAWS(
                response.data.url,
                initialProfileObj.file
              ).then(
                res => {
                  ToastrSerivce({
                    type: 'success',
                    message: 'profile image upload success'
                  });
                  const { child } = data;
                  child.child_image.key = response.data.key;
                  setSubmitting(true);
                  setNewData(data.childId, child);
                  setNewStage(props.stage + 1);
                },
                error => {
                  console.error(error);
                  ToastrSerivce({ type: 'error', message: error.message });
                }
              );
            }
          },
          error => {
            ToastrSerivce({ type: 'error', message: error.message });
          }
        );
      }
    }
  };

  const photoUpload = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!SUPPORTED_FORMATS.includes(file.type)) {
      setError({ message: 'Only JPG/JPGE and PNG allowed' });
    } else if (file.size > FILE_SIZE) {
      setError({ message: 'Image size is too large to upload' });
    }
    reader.onloadend = () => {
      setProfileObj({
        file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };
  const removePicture = () => {
    setProfileObj({
      file: '',
      imagePreviewUrl: profile
    });
    setError(false);
  };
  const skipPicture = () => {
    setNewStage(props.stage + 1);
  };

  return (
    <>
      <Formik initialValues={{ file: '' }} onSubmit={submitData}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <FileInputField
              name="image"
              type="file"
              label="child image"
              filetype="image"
              onChange={photoUpload}
              src={initialProfileObj.imagePreviewUrl}
              multiple={false}
            />
            {initialError ? <p>{initialError.message}</p> : null}

            {initialProfileObj.file !== '' ? (
              <button
                className="button-empty remove-pic-btn"
                onClick={() => removePicture()}
              >
                <span className="fa fa-times remove-pic" aria-hidden="true">
                  <img alt="Remove" src={removeIcon} /> Remove Picture
                </span>
              </button>
            ) : null}
            <button className="button-empty skip" onClick={() => skipPicture()}>
              skip adding child picture and get started
            </button>
            <button
              className="button primary-btn-green"
              type="submit"
              disabled={isSubmitting}
            >
              {LanguageHelper.NEXT_BTN_LABLE}
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

AddChildPicture.propTypes = {
  stage: PropTypes.number.isRequired,
  setNewStage: PropTypes.func.isRequired
};

export { AddChildPicture };
