import React from 'react';

import {
  DiaryEntryMenuItem,
  EventMenuItem,
  AlbumMenuItem
} from './leftColMenuItem';

const CreateSection = () => {
  return (
    <div>
      <div className="dlc-title">Create</div>
      <DiaryEntryMenuItem />
      <AlbumMenuItem />
      <EventMenuItem />
    </div>
  );
};

export { CreateSection };
