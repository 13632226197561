/* eslint-disable no-unused-expressions */
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import {
  key,
  GET_TIMELINE_DATA_LIST,
  GET_TIMELINE_DATA_LIST_SUCCESS,
  GET_TIMELINE_DATA_LIST_ERROR,
  ADD_TIMELINE_DATA_LIST_SUCCESS,
  DELETE_TIMELINE_DATA_LIST_SUCCESS
  // GET_SINGLE_ACTION_ITEM_LIST,
  // GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  // GET_SINGLE_ACTION_ITEM_LIST_ERROR
} from './actions';

export const selectors = {
  getTimelineDataList: state => state[key].getTimelineDataList,
  getTimelineDataSuccess: state => state[key].getTimelineDataSuccess,
  getTimelineDataListError: state => state[key].getTimelineDataListError

  // getSingleActionItemList: state => state[key].getSingleActionItemList,
  // getSingleActionItemListSuccess: state =>
  //   state[key].getSingleActionItemListSuccess,
  // getSingleActionItemListError: state => state[key].getSingleActionItemListError
};

const initialState = {
  getTimelineDataList: {},
  getTimelineDataListSuccess: {},
  getTimelineDataListError: {},
  addATimelineDataListSuccess: {},
  deleteATimelineDataListSuccess: {}

  // singleActionItemList: {},
  // singleActionItemListSuccess: {},
  // singleActionItemListError: {}
};

// reducer
export const reducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_TIMELINE_DATA_LIST:
      return {
        ...state,
        isTimelineDataLoading: true
      };
    case GET_TIMELINE_DATA_LIST_SUCCESS:
      console.log('old timeline', action.payload);
      if (state.childId && state.childId === action.childId) {
        if (state.getTimelineDataListSuccess.data) {
          action.payload.data.map(
            (data, index) =>
              isNil(
                find(
                  state.getTimelineDataListSuccess.data,
                  action.payload.data[index]
                )
              ) &&
              state.getTimelineDataListSuccess.data.push(
                action.payload.data[index]
              )
          );
          return {
            ...state,
            getTimelineDataListSuccess: state.getTimelineDataListSuccess,
            childId: action.childId,
            isLoading: false,
            isTimelineDataLoading: false
          };
        }
      }
      return {
        ...state,
        getTimelineDataListSuccess: action.payload,
        isLoading: false,
        childId: action.childId,
        isTimelineDataLoading: false
      };

    case GET_TIMELINE_DATA_LIST_ERROR:
      return {
        ...state,
        getTimelineDataListError: action.payload,
        isLoading: false,
        isTimelineDataLoading: false
      };

    case ADD_TIMELINE_DATA_LIST_SUCCESS:
      console.log(
        'here',
        action.payload,
        action.payload.children,
        state.getTimelineDataListSuccess.data,
        find(action.payload.children, {
          _id: state.childId
        }),
        state.childId
      );
      if (
        findIndex(state.getTimelineDataListSuccess.data, {
          _id: action.payload._id
        }) > -1
      ) {
        // state.getTimelineDataListSuccess.data[
        //   findIndex(state.getTimelineDataListSuccess.data, {
        //     _id: action.payload._id
        //   })
        // ] = action.payload;
        // state.isLoading = true;
        // state.isTimelineDataLoading = true;
        // return {
        //   ...state,
        //   getTimelineDataListSuccess: state.getTimelineDataListSuccess,
        //   isLoading: false,
        //   isTimelineDataLoading: false
        // };
        if (
          isNil(
            find(action.payload.children, {
              _id: state.childId
            })
          ) &&
          state.childId !== 'all'
        ) {
          state.getTimelineDataListSuccess.data.splice(
            findIndex(state.getTimelineDataListSuccess.data, {
              _id: action.payload._id
            }),
            1
          );
          return {
            ...state,
            getTimelineDataListSuccess: state.getTimelineDataListSuccess,
            addATimelineDataListSuccess: action.payload,
            isLoading: false,
            isTimelineDataLoading: false
          };
        }
        state.getTimelineDataListSuccess.data[
          findIndex(state.getTimelineDataListSuccess.data, {
            _id: action.payload._id
          })
        ] = action.payload;
        state.isLoading = true;
        state.isTimelineDataLoading = true;
        return {
          ...state,
          getTimelineDataListSuccess: state.getTimelineDataListSuccess,
          isLoading: false,
          isTimelineDataLoading: false
        };
      }

      if (
        !isNil(state.childId) &&
        !isNil(
          find(action.payload.children, {
            _id: state.childId
          }) || state.childId === 'all'
        )
      ) {
        if (state.getTimelineDataListSuccess.data === null) {
          state.getTimelineDataListSuccess.data = [action.payload];
        } else {
          state.getTimelineDataListSuccess.data.splice(0, 0, action.payload);
        }

        return {
          ...state,
          getTimelineDataListSuccess: state.getTimelineDataListSuccess,
          addATimelineDataListSuccess: action.payload,
          isLoading: false,
          isTimelineDataLoading: false
        };
      }
      return {
        ...state,
        getTimelineDataListSuccess: action.payload,
        isLoading: false,
        isTimelineDataLoading: false
      };

    case DELETE_TIMELINE_DATA_LIST_SUCCESS:
      if (
        findIndex(state.getTimelineDataListSuccess.data, {
          _id: action.payload.id
        }) > -1
      ) {
        state.getTimelineDataListSuccess.data.splice(
          findIndex(state.getTimelineDataListSuccess.data, {
            _id: action.payload.id
          }),
          1
        );
      }

      if (!isNil(action.payload.type) && action.payload.type === 'event') {
        state.getTimelineDataListSuccess.data.map((post, index) =>
          findIndex(post.event, {
            _id: action.payload.id
          }) > -1
            ? state.getTimelineDataListSuccess.data.splice(index, 1)
            : null
        );
      }
      return {
        ...state,
        singleActionItemLoading: false,
        isLoading: false,
        getTimelineDataListSuccess: state.getTimelineDataListSuccess
      };
    // case GET_SINGLE_ACTION_ITEM_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     singleActionItemListSuccess: action.payload.data[0],
    //     isLoading: false,
    //     singleActionItemLoading: false
    //   };
    // case GET_SINGLE_ACTION_ITEM_LIST_ERROR:
    //   return {
    //     ...state,
    //     singleActionItemListError: action.payload,
    //     isLoading: false,
    //     singleActionItemLoading: false
    //   };
    default:
      return state;
  }
};
