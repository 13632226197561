import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  key,
  GET_USER_CHILDREN_LIST,
  GET_USER_CHILDREN_LIST_SUCCESS,
  GET_USER_CHILDREN_LIST_ERROR,
  ADD_USER_CHILD_SUCCESS,
  ADD_USER_CHILD,
  ADD_USER_CHILD_ERROR,
  ADD_USER_CHILD_BY_INVITATION_SUCCESS
  // GET_SINGLE_ACTION_ITEM_LIST,
  // GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  // GET_SINGLE_ACTION_ITEM_LIST_ERROR
} from './actions';

export const selectors = {
  getUserChildrenList: state => state[key].getUserChildrenList,
  getUserChildrenSuccess: state => state[key].getUserChildrenSuccess,
  getUserChildrenListError: state => state[key].getUserChildrenListError

  // getSingleActionItemList: state => state[key].getSingleActionItemList,
  // getSingleActionItemListSuccess: state =>
  //   state[key].getSingleActionItemListSuccess,
  // getSingleActionItemListError: state => state[key].getSingleActionItemListError
};

const initialState = {
  getUserChildrenList: {},
  getUserChildrenListSuccess: {},
  getUserChildrenListError: {},
  addAUserChild: {},
  addAUserChildSuccess: {},
  addAUserChildError: {},
  addAUserChildByInvitation: {},
  addAUserChildByInvitationSuccess: {},
  addAUserChildByInvitationError: {}

  // singleActionItemList: {},
  // singleActionItemListSuccess: {},
  // singleActionItemListError: {}
};

// reducer
export const reducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_USER_CHILDREN_LIST:
      return {
        ...state,
        isUserChildrenLoading: true
      };
    case GET_USER_CHILDREN_LIST_SUCCESS:
      return {
        ...state,
        getUserChildrenListSuccess: action.payload,
        isLoading: false,
        isUserChildrenLoading: false
      };
    case GET_USER_CHILDREN_LIST_ERROR:
      return {
        ...state,
        getUserChildrenListError: action.payload,
        isLoading: false,
        isUserChildrenLoading: false
      };
    case ADD_USER_CHILD:
      return {
        ...state,
        isLoading: true
      };
    case ADD_USER_CHILD_SUCCESS:
      console.log('childState', state, action);
      if (!isEmpty(state.getUserChildrenListSuccess)) {
        state.getUserChildrenListSuccess.data.children.push(action.payload);
      }
      return {
        ...state,
        addAUserChildSuccess: action.payload,
        isLoading: false,
        isUserChildrenLoading: false
      };
    case ADD_USER_CHILD_ERROR:
      return {
        ...state,
        addAUserChildError: action.payload,
        isLoading: false,
        isUserChildrenLoading: false
      };
    case ADD_USER_CHILD_BY_INVITATION_SUCCESS:
      console.log('childState', state, action);
      if (isNil(state.getUserChildrenListSuccess.data.children)) {
        console.log('here');
        state.getUserChildrenListSuccess.data.children = action.payload;
      } else {
        action.payload.map(child =>
          state.getUserChildrenListSuccess.data.children.push(child)
        );
        // state.getUserChildrenListSuccess.data.children = state.getUserChildrenListSuccess.data.children.concat(action.payload);
      }
      return {
        ...state,
        getUserChildrenListSuccess: state.getUserChildrenListSuccess,
        isLoading: false,
        isUserChildrenLoading: false
      };

    // case GET_SINGLE_ACTION_ITEM_LIST:
    //   return {
    //     ...state,
    //     singleActionItemLoading: true,
    //     isLoading: true,
    //     singleActionItemListSuccess: {}
    //   };
    // case GET_SINGLE_ACTION_ITEM_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     singleActionItemListSuccess: action.payload.data[0],
    //     isLoading: false,
    //     singleActionItemLoading: false
    //   };
    // case GET_SINGLE_ACTION_ITEM_LIST_ERROR:
    //   return {
    //     ...state,
    //     singleActionItemListError: action.payload,
    //     isLoading: false,
    //     singleActionItemLoading: false
    //   };
    default:
      return state;
  }
};
