import React, { useEffect, useRef, useState } from 'react';
import uuid from 'uuid/v4';

import { useDispatch } from 'react-redux';
import { CommonMenuItem } from '../../commonMenuItem/CommonMenuItem';
import Icon from '../../../../icomoon/icon';
import { AcceptInvitationForm } from '../../../home/acceptInvitationForm/AcceptInvitationForm';
import { PopupModal } from '../../popupModal/PopupModal';
import { ValidateInvitationForm } from '../../../home/acceptInvitationForm/ValidateInvitationOtpForm';
import { LanguageHelper } from '../../../../../helpers/languageHelpers/En';
import { addAUserChildByInvitationSuccess } from '../../../../../features/userChildren/actions';
import { addOtherChildByInvitationSuccess } from '../../../../../features/otherChildren/actions';

const AcceptInvitationMenuItem = () => {
  const [stage, setStage] = useState(0);
  const [invitationData, setInvitationData] = useState({});
  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);
  const dispatch = useDispatch();
  const [titleAndDescription, setTitleAndDescription] = useState({
    popUpTile: LanguageHelper.ACCEPT_INVITE_OTP_HEADING,
    popUpDescription: LanguageHelper.ACCEPT_INVITE_OTP_DESCRIPTION
  });
  const [initailMenuItem, setMenuItem] = useState({
    acceptInvitation: false
  });
  const acceptInvitation = (value, data = null, relationship = null) => {
    setMenuItem({
      acceptInvitation: value
    });
    setStage(0);
    if (_isMounted.current) {
      if (data !== null) {
        if (
          relationship === 'father' ||
          relationship === 'mother' ||
          relationship === 'guardian'
        ) {
          dispatch(addAUserChildByInvitationSuccess(data));
        } else {
          dispatch(addOtherChildByInvitationSuccess(data));
        }
      }
    }
  };

  const setRespone = response => {
    setInvitationData({ response });
  };

  const nextView = param => {
    if (param >= 0) {
      setStage(param);
      if (param === 1) {
        setTitleAndDescription({
          popUpTile: LanguageHelper.ACCEPT_INVITE_HEADING,
          popUpDescription: null
        });
      }
    }
  };
  let compt = null;
  if (stage === 0) {
    compt = (
      <ValidateInvitationForm
        stage={stage}
        setRespone={setRespone}
        setNewStage={nextView}
        key={uuid()}
      />
    );
  } else if (stage === 1) {
    compt = (
      <AcceptInvitationForm
        onCancel={acceptInvitation}
        stage={stage}
        data={invitationData}
        key={uuid()}
      />
    );
  }
  return (
    <div>
      <div onClick={() => acceptInvitation(true)} role="button">
        <CommonMenuItem
          buttonClass="nav-link nav-link-btn d-flex align-items-center"
          iconClass="bt-icon-wrapper icon-circle-45 icon-circle-light-green"
          title="Accept invitation"
          description=""
          iconComponent={
            <Icon
              icon="icon-invitation"
              size={25}
              color="#2EC99F"
              className="nl-icon"
            />
          }
        />
      </div>
      <PopupModal
        closable={false}
        centered
        footer={null}
        visible={initailMenuItem.acceptInvitation}
        onOk={() => acceptInvitation(false)}
        onCancel={() => acceptInvitation(false)}
        closeBtn
        modalStyle="ant-modal default-modal"
        popupTitle={titleAndDescription.popUpTile}
        popupDescription={titleAndDescription.popUpDescription}
        stage={stage}
        backBtn={false}
      >
        {compt}
      </PopupModal>
    </div>
  );
};

export { AcceptInvitationMenuItem };
