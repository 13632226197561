/* -------------------------------------------------------------
 * unique key namespace used by combineReducers.
 * By convention it will match the directory structure to
 * make it easy to locate the src.
 * Also action types will prefix with the capitalized version
 * -------------------------------------------------------------
 */

export const key = 'userChildren';

/* -----------------------------
 * Actions
 * -----------------------------
 */

// retrieve filter data
export const GET_USER_CHILDREN_LIST = 'GET_USER_CHILDREN_LIST';
export const GET_USER_CHILDREN_LIST_SUCCESS = 'GET_USER_CHILDREN_LIST_SUCCESS';
export const GET_USER_CHILDREN_LIST_ERROR = 'GET_USER_CHILDREN_LIST_ERROR';

// add a child
export const ADD_USER_CHILD = 'ADD_USER_CHILD';
export const ADD_USER_CHILD_SUCCESS = 'ADD_USER_CHILD_SUCCESS';
export const ADD_USER_CHILD_ERROR = 'ADD_USER_CHILD_ERROR';

// add a child image
export const ADD_USER_CHILD_IMAGE = 'ADD_USER_CHILD_IMAGE';
export const ADD_USER_CHILD_IMAGE_SUCCESS = 'ADD_USER_CHILD_IMAGE_SUCCESS';
export const ADD_USER_CHILD_IMAGE_ERROR = 'ADD_USER_CHILD_IMAGE_ERROR';

// add a child from invitations
export const ADD_USER_CHILD_BY_INVITATION = 'ADD_USER_CHILD_BY_INVITATION';
export const ADD_USER_CHILD_BY_INVITATION_SUCCESS =
  'ADD_USER_CHILD_BY_INVITATION_SUCCESS';
export const ADD_USER_CHILD_BY_INVITATION_ERROR =
  'ADD_USER_CHILD_BY_INVITATION_ERROR';

// export const GET_SINGLE_ACTION_ITEM_LIST = 'GET_SINGLE_ACTION_ITEM_LIST';
// export const GET_SINGLE_ACTION_ITEM_LIST_SUCCESS =
//   'GET_SINGLE_ACTION_ITEM_LIST_SUCCESS';
// export const GET_SINGLE_ACTION_ITEM_LIST_ERROR =
//   'GET_SINGLE_ACTION_ITEM_LIST_ERROR';

export const actionTypes = {
  GET_USER_CHILDREN_LIST,
  GET_USER_CHILDREN_LIST_SUCCESS,
  GET_USER_CHILDREN_LIST_ERROR,
  ADD_USER_CHILD,
  ADD_USER_CHILD_SUCCESS,
  ADD_USER_CHILD_ERROR,
  ADD_USER_CHILD_IMAGE,
  ADD_USER_CHILD_IMAGE_SUCCESS,
  ADD_USER_CHILD_IMAGE_ERROR,
  ADD_USER_CHILD_BY_INVITATION,
  ADD_USER_CHILD_BY_INVITATION_SUCCESS,
  ADD_USER_CHILD_BY_INVITATION_ERROR

  //   GET_SINGLE_ACTION_ITEM_LIST,
  //   GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  //   GET_SINGLE_ACTION_ITEM_LIST_ERROR
};

/* -----------------------------
 * Action Creators
 * -----------------------------
 */
export const getUserChildrenList = request => ({
  type: GET_USER_CHILDREN_LIST,
  payload: request
});

export const getUserChildrenListSuccess = response => ({
  type: GET_USER_CHILDREN_LIST_SUCCESS,
  payload: response
});

export const getUserChildrenListError = error => ({
  type: GET_USER_CHILDREN_LIST_ERROR,
  payload: error
});

export const addAUserChild = request => ({
  type: ADD_USER_CHILD,
  payload: request
});

export const addAUserChildSuccess = request => ({
  type: ADD_USER_CHILD_SUCCESS,
  payload: request
});

export const addAChildError = error => ({
  type: ADD_USER_CHILD_ERROR,
  payload: error
});

export const addAUserChildImage = request => ({
  type: ADD_USER_CHILD_IMAGE,
  payload: request
});

export const addAUserChildImageSuccess = request => ({
  type: ADD_USER_CHILD_IMAGE_SUCCESS,
  payload: request
});

export const addAChildImageError = error => ({
  type: ADD_USER_CHILD_IMAGE_ERROR,
  payload: error
});

export const addAUserChildByInvitation = request => ({
  type: ADD_USER_CHILD_BY_INVITATION,
  payload: request
});

export const addAUserChildByInvitationSuccess = request => ({
  type: ADD_USER_CHILD_BY_INVITATION_SUCCESS,
  payload: request
});

export const addAChildByInvitationError = error => ({
  type: ADD_USER_CHILD_BY_INVITATION_ERROR,
  payload: error
});
// export const getSingleActionItemListSuccess = response => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
//   payload: response
// });

// export const getSingleActionItemListError = error => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_ERROR,
//   payload: error
// });

export const actions = {
  getUserChildrenList,
  getUserChildrenListSuccess,
  getUserChildrenListError,
  addAUserChild,
  addAUserChildSuccess,
  addAChildError

  //   getSingleActionItemList,
  //   getSingleActionItemListSuccess,
  //   getSingleActionItemListError
};
