import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { OtpInputField } from '../../../common/commonFields';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { validateInvitationPasscode } from '../../../../api/invitation/InvitationApi';
import { getSingleData } from '../../../../config/localStorage';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';

const ValidateInvitationForm = props => {
  const { stage, setRespone, setNewStage } = props;
  const [otp, setState] = useState('');
  const [initialError, setError] = useState(false);

  const handleOtpChange = passcode => {
    setState(passcode);
  };

  const submitData = (values, { setSubmitting }) => {
    if (otp.toString().length === 8) {
      const payload = {
        code: otp.toString(),
        email: JSON.parse(getSingleData('current-user')).email
      };
      validateInvitationPasscode(payload)
        .then(response => {
          if (response.success) {
            setSubmitting(true);
            setNewStage(stage + 1);
            setRespone(response.data.invitation);
            ToastrSerivce({ type: 'success', message: response.message });
          } else {
            setSubmitting(false);
            ToastrSerivce({ type: 'error', message: 'invalid passcode' });
          }
        })
        .catch(error => {
          if (error.message) {
            ToastrSerivce({ type: 'error', message: error.message });
          } else {
            ToastrSerivce({ type: 'error', message: error.error });
          }
        });
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          otp: ''
        }}
        onSubmit={submitData}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <OtpInputField
              name="otp"
              numInputs="8"
              onChange={handleOtpChange}
              isInputNum="true"
              shouldAutoFocus
              value={otp}
            />
            {initialError ? <p>Invalid passcode length</p> : null}
            <button
              className="button primary-btn-green"
              type="submit"
              disabled={isSubmitting}
            >
              {LanguageHelper.NEXT_BTN_LABLE}
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

ValidateInvitationForm.propTypes = {
  stage: PropTypes.number.isRequired,
  setRespone: PropTypes.func.isRequired,
  setNewStage: PropTypes.func.isRequired
};
export { ValidateInvitationForm };
