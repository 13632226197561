import React from 'react';
import { Menu, Dropdown } from 'antd';

import Icon from '../icomoon/icon';
import { DropdownMenuItem } from './common/dropdownMenuItem/DropdownMenuItem';
import { CommonMenuItem } from './common/commonMenuItem/CommonMenuItem';
import { AllMenuItem } from './common/notificationDrawer/MenuItems/AllMenuItem';
import { UnreadMenuItem } from './common/notificationDrawer/MenuItems/UnreadMenuItem';
import { MarkAllAsReadMenuItem } from './common/notificationDrawer/MenuItems/MarkAllAsReadMenuItem';

const EditOptionsMenu = props => {
  const { clickAll, clickUnread, clickMarkAsRead } = props;
  const menuItems = [
    <div role="button" onClick={() => clickAll()}>
      <AllMenuItem />
    </div>,
    <div role="button" onClick={() => clickUnread()}>
      <UnreadMenuItem />
    </div>,
    <div role="button" onClick={() => clickMarkAsRead()}>
      <MarkAllAsReadMenuItem />
    </div>
  ];

  return (
    <DropdownMenuItem
      overlayClassName="edit-options-dropdown"
      placement="bottomRight"
      menuItems={menuItems}
    >
      <CommonMenuItem
        iconClass="bt-icon-wrapper icon-circle-45"
        iconComponent={
          <Icon
            icon="icon-option"
            size={22}
            color="#2EC99F"
            className="nl-icon"
          />
        }
      />
    </DropdownMenuItem>
  );
};

export { EditOptionsMenu };
