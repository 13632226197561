import * as ApiConnector from '../ApiConnector';
import { KidsAppHelper } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const userLogin = async payload => {
  try {
    const { data } = await ApiConnector.post(KidsAppHelper.loginUrl, payload);
    return data;
  } catch (exception) {
    console.error(`error ${exception.message}`);
    throw exception;
  }
};

export const userFbLogin = async payload => {
  try {
    const { data } = await ApiConnector.post(KidsAppHelper.loginUrlFB, payload);
    return data;
  } catch (exception) {
    console.error(`error ${exception.message}`);
    throw exception;
  }
};

export const userGoogleLogin = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.loginUrlGoogle,
      payload
    );
    return data;
  } catch (exception) {
    console.error(`error ${exception.message}`);
    throw exception;
  }
};

export const resetPassword = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.passwordResetToken,
      payload
    );
    return data;
  } catch (exception) {
    console.error(`error ${exception.message}`);
    throw exception;
  }
};

export const resetPasswordOtp = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.resetPasswordOtp,
      payload
    );
    return data;
  } catch (exception) {
    console.error(`error ${exception.message}`);
    throw exception;
  }
};

export const resetPasswordNewPassword = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelper.resetPassword,
      payload
    );
    return data;
  } catch (exception) {
    console.error(`error ${exception.message}`);
    throw exception;
  }
};

// api to get user children
export const getUser = async id => {
  try {
    const { data } = await ApiConnector.get(
      `${KidsAppHelper.userUpdateUrl}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// api to auth firebase
export const firebaseAuth = async webtoken => {
  const config = {
    headers: {
      post: {
        Authorization: `Bearer ${webtoken}`
      }
    }
  };
  try {
    const { data } = await ApiConnector.post(
      `${KidsAppHelper.firebaseAuth}`,
      '',
      config
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
