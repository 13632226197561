import React from 'react';
import { Divider, Switch } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  CreateSection,
  OtherSection,
  SearchLeftColSection
} from '../../../dashboard/common/leftCol';
import { ProfileCard } from '../../../dashboard/common/leftCol/ProfileCard';

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const LeftLayout = props => {
  const history = useHistory();
  const { childId, search, userId, myChild } = props;
  console.log(props);
  let profileCard = '';
  let searchlayout = '';
  if (childId) {
    profileCard = <ProfileCard id={childId} type="child" myChild={myChild} />;
  } else {
    profileCard = <ProfileCard id={userId} type="user" />;
  }
  if (search) {
    searchlayout = <SearchLeftColSection searchParams={search} />;
  }
  return (
    <div className="d-none d-sm-block">
      <div className="dash-left-col d-flex flex-column justify-content-between sticky-top">
        {!search ? (
          <div>
            <div>{profileCard}</div>
            <div>
              <CreateSection />
            </div>
            <Divider style={{ backgroundColor: '#EAEAEA', margin: '30px 0' }} />
            <div>
              <OtherSection />
            </div>
            <Divider style={{ backgroundColor: '#EAEAEA', margin: '30px 0' }} />
            <div className="dark-mood-wrapper">
              <div className="d-flex align-items-center justify-content-between">
                <div className="dm-title">Dark Mode</div>
                <Switch
                  defaultChecked
                  onChange={onChange}
                  className="sw-dark-mood"
                />
              </div>
              <div className="dm-description">
                Switch your board for dark mode
              </div>
            </div>
          </div>
        ) : (
          <div className="ishata">{searchlayout}</div>
        )}

        <div className="copyright-wrapper">
          <div className="copyright-text">
            © 2020 Child's Circle. All rights reserved
          </div>
          <div className="terms-text">
            <a href="#">Terms and Conditions</a> |{' '}
            <a href="#">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LeftLayout };
