import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { UserAvatar } from '../UserAvatar';
import avatar from '../../../assets/images/login.jpg';
import { dateConvert } from '../../../core/common/index';

const ChildCard = props => {
  const { firstName, lastName, dob, profileImage, id, myChild } = props;
  const history = useHistory();

  const timeFormDOB = dateConvert(dob);

  const navigateNext = () => {
    history.push(`/timeLine/${id}`, { myChild });
  };

  return (
    <div className="col-md-6 col-lg-4">
      <div className="dash-card dash-card-child-bg d-flex align-items-center justify-content-center">
        <div
          className="d-flex align-items-center flex-row flex-sm-column"
          style={{ width: '100%' }}
        >
          <UserAvatar size={100} shape="circle" src={profileImage} />
          <div className="dc-details-wrapper">
            <div className="dc-child-name">
              {firstName} {lastName}
            </div>
            <div className="dc-child-dob">{timeFormDOB}</div>
            <button
              className="button card-btn-light-green"
              onClick={() => navigateNext()}
            >
              View Timeline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ChildCard.defaultProps = {
  profileImage: avatar
};
ChildCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
  id: PropTypes.string.isRequired,
  myChild: PropTypes.bool.isRequired
};

export { ChildCard };
