import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { dependencies, store } from './store';
import routes from './routes';
import PrivateRoute from './guards/privateRoute/PrivateRoute';

function App() {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );
  const routeComponents = routes.map((data, index) => {
    return data.auth
      ? data.component && (
          <PrivateRoute
            path={data.path}
            exact
            layouts={data.layouts}
            // eslint-disable-next-line react/jsx-props-no-spreading
            component={data.component}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        )
      : data.component && (
          <Route
            path={data.path}
            exact
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={props => (
              <data.layouts.mainLayout>
                <data.component {...props} />
              </data.layouts.mainLayout>
            )}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        );
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={dependencies.history}>
        {/* <BrowserRouter> */}
        <Suspense fallback={loading}>
          <Switch>{routeComponents}</Switch>
        </Suspense>
        {/* </BrowserRouter> */}
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
