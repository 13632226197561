import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isNil from 'lodash/isNil';
import size from 'lodash/size';

import { UserAvatar } from '../../UserAvatar';
import Icon from '../../../icomoon/icon';

import cameraIcon from '../../../../assets/images/dasboard/cl-album.png';
import { awsGetASingleImage } from '../../../../api/signUrl/AWSSignUrl';

const AlbumNotificationContent = props => {
  const { data } = props;
  console.log(data.seen);
  const [imageKeys, setImageKeys] = useState([]);
  const [albumImages, setAlbumImages] = useState({
    loading: true,
    images: []
  });

  const files = [];
  useEffect(() => {
    if (!isNil(data.meta.media)) {
      data.meta.media.map((image, index) =>
        index < 3 ? files.push({ key: image.content }) : null
      );
    }

    setImageKeys(files);
  }, [props]);

  useEffect(() => {
    let isMounted = true;
    awsGetASingleImage({
      files: imageKeys,
      location: 'post-images',
      size: 'medium'
    })
      .then(response => {
        if (isMounted) {
          setAlbumImages({
            loading: false,
            images: response.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      isMounted = false;
    };
  }, [imageKeys]);
  return (
    <>
      {/* Notification - If there is unread notifications "notify-unread" this class need to be added to "notify-message-wrapper" div */}
      <div
        className={
          !data.seen
            ? 'notify-message-wrapper d-flex notification-unseen'
            : 'notify-message-wrapper d-flex'
        }
      >
        <UserAvatar
          size={53}
          shape="circle"
          src={data.meta.created_by[0].profile_image}
          style={{ marginRight: 15 }}
        />
        <div className="nm-right">
          <div className="d-flex align-items-center justify-content-between">
            <div className="nm-name">
              {!isNil(data.meta.created_by[0].first_name) &&
                `${data.meta.created_by[0].first_name} ${data.meta.created_by[0].last_name}`}
            </div>
            <div className="nm-date">
              {' '}
              {moment
                .utc(data.meta.post_created_at)
                .local()
                .fromNow()}
            </div>
          </div>
          <div className="nm-subtitle d-flex">
            {/* Icons - Only for desktop */}
            <img
              className="d-none d-sm-block"
              src={cameraIcon}
              alt={cameraIcon}
            />
            <span>
              Added {size(data.meta.media)} Photos to {data.meta.child_name}’s
              Timeline
            </span>
          </div>
          <div className="nm-photos-wrapper d-flex align-items-center">
            {albumImages.images.map((img, index) => {
              return <img src={img.url} alt={index} key={index} />;
            })}
            {size(data.meta.media) > 3 ? (
              <div className="more-photos-small d-flex align-items-center">
                <Icon
                  icon="icon-pluse"
                  size={10}
                  color="#333333"
                  className="nl-icon"
                />
                <div className="photo-count">{size(data.meta.media) - 3}</div>
              </div>
            ) : null}
          </div>
          {/* This section need to be visibled with the quote and comment */}
          {/* <div className="nm-description">“Thank you for the evening, the party was great. We all had fun!!! The thank you gift Is greatly appreciated”</div> */}
        </div>
      </div>
    </>
  );
};

export { AlbumNotificationContent };
