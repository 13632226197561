import React from 'react';
import logo from '../../assets/images/logo.svg';

const TitleLayout = props => {
  return (
    <div className="logo-container">
      <img src={logo} alt="Logo" />
    </div>
  );
};

export { TitleLayout };
