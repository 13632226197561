import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import {
  DatePickerField,
  CustomInputFields,
  DropDownField
} from '../../../common/commonFields';
import {
  firstNameYupSchema,
  lastNameYupSchema,
  dateOfBirthYupSchema,
  genderYupSchema,
  relationshipYupSchema
} from '../../../common/commonFields/formValidationSchema/FormValidationSchema';
import { addChild } from '../../../../api/child/ChildApi';
import { getSingleData, setSingleData } from '../../../../config/localStorage';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';

const validationSchema = Yup.object().shape({
  firstName: firstNameYupSchema,
  lastName: lastNameYupSchema,
  dob: dateOfBirthYupSchema,
  gender: genderYupSchema,
  relationship: relationshipYupSchema
});

const AddChildFrom = props => {
  const { stage, setNewData, setNewStage } = props;
  const optionList = [
    { value: 'male', label: 'Male', key: 'male' },
    { value: 'female', label: 'Female', key: 'female' }
  ];
  const relationshipList = [
    { value: 'father', label: 'Father', key: 'father' },
    { value: 'mother', label: 'Mother', key: 'mother' },
    { value: 'guardian', label: 'Guardian', key: 'guardian' }
  ];

  const submitData = (values, { setSubmitting }) => {
    setNewStage(stage + 1);
    const payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      dob: values.dob,
      gender: values.gender
    };
    payload[values.relationship] = JSON.parse(
      getSingleData('current-user')
    )._id;
    addChild(payload)
      .then(response => {
        setSingleData('relationship', values.relationship);
        ToastrSerivce({ type: 'success', message: response.message });
        setSubmitting(true);
        setNewStage(stage + 1);
        setNewData(response.data.child.id, response.data.child);
      })
      .catch(error => {
        console.log(error);
        ToastrSerivce({ type: 'error', message: error.message });
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          dob: '',
          gender: 'male',
          relationship: 'father'
        }}
        validationSchema={validationSchema}
        onSubmit={submitData}
      >
        {({ handleSubmit, handleChange, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <CustomInputFields
              name="firstName"
              type="text"
              label="Child's First Name *"
              placeholder="Child's First Name"
            />
            <CustomInputFields
              name="lastName"
              type="text"
              label="Child's Last Name *"
              placeholder="Child's Last Name"
            />

            <DatePickerField
              name="dob"
              placeholderText="Date of Birth"
              label="Date Of Birth *"
              maxDate={new Date()}
            />

            <DropDownField
              id="gender"
              name="gender"
              placeholder="Gender"
              label="Gender *"
              options={optionList}
              onChange={handleChange}
            />
            <DropDownField
              id="relationship"
              name="relationship"
              placeholder="Relationship"
              label="Relationship *"
              options={relationshipList}
              onChange={handleChange}
            />

            <button
              className="button primary-btn-green"
              type="submit"
              disabled={isSubmitting}
            >
              {LanguageHelper.NEXT_BTN_LABLE}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

AddChildFrom.propTypes = {
  stage: PropTypes.number.isRequired,
  setNewData: PropTypes.func.isRequired,
  setNewStage: PropTypes.func.isRequired
};

export { AddChildFrom };
