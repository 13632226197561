import React from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import InputTypeErrors from '../inputTypeErrors/InputTypeErrors';

const CustomInputFields = ({ label, placeholder, searchClass, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  let inputClassStyle = 'form-control';
  let divStyle = {
    width: '100%'
  };
  if (props.stage === 1) {
    inputClassStyle = 'verify-input';
    divStyle = {
      width: '25%',
      display: 'inline-flex'
    };
  }

  if (!isNil(searchClass)) {
    inputClassStyle = 'form-control form-control-lg';
  }
  return (
    <div style={divStyle} className="form-group">
      <label>{label}</label>
      {props.onChange ? (
        <input
          className={inputClassStyle}
          {...field}
          {...props}
          value={field.value || ''}
          placeholder={placeholder}
          autoComplete="off"
          onChange={e => {
            e.preventDefault();
            const { value } = e.target;
            const regex = /^[0-9]*$/;
            if (regex.test(value.toString())) {
              setFieldValue('zip', value);
            }
          }}
        />
      ) : (
        <input
          className={inputClassStyle}
          {...field}
          {...props}
          value={field.value || ''}
          placeholder={placeholder}
          autoComplete="off"
        />
      )}

      <InputTypeErrors errors={meta} />
    </div>
  );
};
CustomInputFields.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export { CustomInputFields };
