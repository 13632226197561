import {
  key,
  GET_AVATAR_IMAGE_LIST,
  GET_AVATAR_IMAGE_SUCCESS,
  GET_AVATAR_IMAGE_ERROR
  // GET_SINGLE_ACTION_ITEM_LIST,
  // GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  // GET_SINGLE_ACTION_ITEM_LIST_ERROR
} from './actions';

export const selectors = {
  getAvatarImageList: state => state[key].getAvatarImageList,
  getAvatarImageSuccess: state => state[key].getAvatarImageSuccess,
  getAvatarImageListError: state => state[key].getAvatarImageListError

  // getSingleActionItemList: state => state[key].getSingleActionItemList,
  // getSingleActionItemListSuccess: state =>
  //   state[key].getSingleActionItemListSuccess,
  // getSingleActionItemListError: state => state[key].getSingleActionItemListError
};

const initialState = {
  getAvatarImageList: {},
  getAvatarImageSuccess: {
    data: []
  },
  getAvatarImageListError: {}

  // singleActionItemList: {},
  // singleActionItemListSuccess: {},
  // singleActionItemListError: {}
};

// reducer
export const reducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_AVATAR_IMAGE_LIST:
      return {
        ...state,
        isTimelineDataLoading: true
      };
    case GET_AVATAR_IMAGE_SUCCESS:
      state.getAvatarImageSuccess.data.push({
        key: action.imageKey,
        url: action.payload
      });
      return {
        ...state,
        getAvatarImageSuccess: state.getAvatarImageSuccess,
        isLoading: false
      };

    case GET_AVATAR_IMAGE_ERROR:
      return {
        ...state,
        getAvatarImageListError: action.payload,
        isLoading: false
      };

    // case GET_SINGLE_ACTION_ITEM_LIST:
    //   return {
    //     ...state,
    //     singleActionItemLoading: true,
    //     isLoading: true,
    //     singleActionItemListSuccess: {}
    //   };
    // case GET_SINGLE_ACTION_ITEM_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     singleActionItemListSuccess: action.payload.data[0],
    //     isLoading: false,
    //     singleActionItemLoading: false
    //   };
    // case GET_SINGLE_ACTION_ITEM_LIST_ERROR:
    //   return {
    //     ...state,
    //     singleActionItemListError: action.payload,
    //     isLoading: false,
    //     singleActionItemLoading: false
    //   };
    default:
      return state;
  }
};
