import React, { useState } from 'react';

import Icon from '../../../../icomoon/icon';

const AllMenuItem = props => {
  return (
    <div className="d-flex align-items-center">
      <a onClick={e => e.preventDefault()} role="button">
        All
      </a>
    </div>
  );
};

export { AllMenuItem };
