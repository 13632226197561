import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import size from 'lodash/size';
import { getUserChildren } from '../../../../api/child/ChildApi';
import { getSingleData } from '../../../../config/localStorage';

import {
  InviteToFamilyMenuItem,
  AcceptInvitationMenuItem,
  ManageChildrenMeunItem
} from './leftColMenuItem';

const OtherSection = props => {
  const dispatch = useDispatch();
  const [childrenData, setChildrenData] = useState([]);
  useEffect(() => {
    dispatch(getUserChildren(JSON.parse(getSingleData('current-user'))._id));
  }, []);

  const { getUserChildrenListSuccess, isUserChildrenLoading } = useSelector(
    state => state.userChildren
  );

  useEffect(() => {
    if (getUserChildrenListSuccess.data) {
      setChildrenData(getUserChildrenListSuccess.data.children);
    }
  }, [getUserChildrenListSuccess.data]);

  return (
    <div>
      <div className="dlc-title">Other</div>
      <InviteToFamilyMenuItem disable={!(size(childrenData) > 0)} />
      <AcceptInvitationMenuItem />
      <ManageChildrenMeunItem />
    </div>
  );
};

export { OtherSection };
