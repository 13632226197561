import React from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import {
  AllMenuItem,
  ChildrenMenuItem,
  AlbumMenuItem,
  DiaryEntryMenuItem,
  EventMenuItem,
  GuardiansMenuItem
} from './SearchColMenuItem';

const SearchLeftColSection = props => {
  const history = useHistory();
  const { searchParams } = props;
  const params = queryString.parse(searchParams);
  const search = filter => {
    history.push({
      pathname: '/search',
      search: `?limit=${params.limit}&page=${params.page}&filter=${filter}&search=${params.search}`
    });
  };
  return (
    <div>
      <div className="dlc-title" />
      <div onClick={() => search('all')} role="button">
        <AllMenuItem />
      </div>
      <div onClick={() => search('children')} role="button">
        <ChildrenMenuItem />
      </div>
      <div onClick={() => search('guardians')} role="button">
        <GuardiansMenuItem />
      </div>
      <div onClick={() => search('diary')} role="button">
        <DiaryEntryMenuItem />
      </div>
      <div onClick={() => search('albums')} role="button">
        <AlbumMenuItem />
      </div>
      <div onClick={() => search('event')} role="button">
        <EventMenuItem />
      </div>
    </div>
  );
};
SearchLeftColSection.propTypes = {
  searchParams: PropTypes.string.isRequired
};

export { SearchLeftColSection };
