import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import moment from 'moment';

import {
  DatePickerField,
  CustomInputFields,
  MultipleImageUpload,
  CustomTextArea
} from '../../../common/commonFields';
import { ChildrenAvatarLoop } from '../../common/chidrenAvatarLoop/ChildrenAvatarLoop';
import {
  dateOfBirthYupSchema,
  eventTitleYupSchema,
  diaryEntryDescriptionYupSchema,
  locationYupSchema
} from '../../../common/commonFields/formValidationSchema/FormValidationSchema';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { addEvent, getEvent } from '../../../../api/userEvent/UserEvent';
import { deleteMedia } from '../../../../api/userPost/UserPost';
import { userProfileUploadAWS } from '../../../../api/userRegistration/UserRegistrationApi';
import { getSingleData } from '../../../../config/localStorage';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';

const AddEventForm = props => {
  const { edit, id, onCancel } = props;
  const [initialValues, setInitialValues] = useState({
    eventTitle: '',
    eventDescription: '',
    eventDate: '',
    eventLocation: ''
  });
  const [selectedChildrenData, setSelectedChildrenData] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);
  const [editStateEventImages, setEditStateEventImages] = useState([]);
  const [imagesToAdd, setImagesToAdd] = useState();
  const [videosToAdd, setVideosToAdd] = useState();

  const [updatedEvent, setUpdatedEvent] = useState({});
  const [imageUploadCount, setImageUploadCount] = useState(0);
  const [videosUploadCount, setVideosUploadCount] = useState(0);
  const [removeImagesCount, setRemoveImagesCount] = useState(0);

  const [showUploadMessage, setShowUploadMessage] = useState(null);

  useEffect(() => {
    if (!isEmpty(updatedEvent)) {
      if (
        imageUploadCount + videosUploadCount + removeImagesCount ===
        size(imagesToAdd) + size(videosToAdd) + size(removeImages)
      ) {
        setTimeout(() => onCancel(false, updatedEvent), 2000);
      }
    }
  }, [updatedEvent, imageUploadCount, videosUploadCount, removeImagesCount]);

  useEffect(() => {
    if (edit) {
      getEvent(id)
        .then(response => {
          const childrenData = [];
          setInitialValues({
            eventTitle: response.data.event[0].title,
            eventDescription: response.data.event[0].description,
            eventDate: moment(response.data.event[0].event_date).format(
              'yyyy-MM-DD'
            ),
            eventLocation: response.data.event[0].location
          });
          response.data.children.map(child => childrenData.push(child._id));
          setSelectedChildrenData(childrenData);
          setEditStateEventImages(response.data.media);
          setImagesToAdd([]);
          setVideosToAdd([]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  const onLoadSelectedChidData = data => {
    setSelectedChildrenData(data);
  };

  const submitData = (values, { setSubmitting }) => {
    if (size(selectedChildrenData) === 0) {
      ToastrSerivce({ type: 'error', message: 'select one or more children' });
      setSubmitting(false);
    } else if (!edit && size(imagesToAdd) === 0 && size(videosToAdd) === 0) {
      ToastrSerivce({
        type: 'error',
        message: 'add images or videos to event'
      });
      setSubmitting(false);
    } else if (
      size(editStateEventImages) === 0 &&
      size(imagesToAdd) === 0 &&
      size(videosToAdd) === 0
    ) {
      ToastrSerivce({
        type: 'error',
        message: 'add images or videos to evnrt to edit'
      });
      setSubmitting(false);
    } else {
      const eventData = {
        title: values.eventTitle,
        children: selectedChildrenData,
        content_type: 'event',
        description: values.eventDescription,
        event_date: values.eventDate,
        location: values.eventLocation,
        count: {
          image: imagesToAdd.length,
          video: videosToAdd.length
        },
        created_by: JSON.parse(getSingleData('current-user'))._id
      };
      addEvent(eventData, edit, id)
        .then(response => {
          setUpdatedEvent(response.data);
          setSubmitting(true);
          setShowUploadMessage('finishing up please wait ....');
          if (
            size(response.data.images) > 0 ||
            size(response.data.video) > 0 ||
            size(removeImages) > 0
          ) {
            response.data.images.map((data, index) =>
              userProfileUploadAWS(data, imagesToAdd[index]).then(
                res => {
                  ToastrSerivce({
                    type: 'success',
                    message: 'image upload success'
                  });
                  setImageUploadCount(index + 1);
                },
                error => {
                  console.error(error);
                  ToastrSerivce({ type: 'error', message: error.message });
                }
              )
            );
            response.data.videos.map((data, index) =>
              userProfileUploadAWS(data, videosToAdd[index]).then(
                res => {
                  ToastrSerivce({
                    type: 'success',
                    message: 'video upload success'
                  });
                  setVideosUploadCount(index + 1);
                },
                error => {
                  console.error(error);
                  ToastrSerivce({ type: 'error', message: error.message });
                }
              )
            );
            if (removeImages.length > 0) {
              removeImages.map((data, index) =>
                deleteMedia(data).then(
                  res => {
                    setRemoveImagesCount(index + 1);
                  },
                  error => {
                    console.error(error);
                    ToastrSerivce({ type: 'error', message: error.message });
                  }
                )
              );
            }
          }
          ToastrSerivce({
            type: 'success',
            message: response.message
          });
          // onCancel(false, response.data);
        })
        .catch(error => {
          console.log(error);
          ToastrSerivce({ type: 'error', message: error.message });
        });
    }
  };

  const validationSchema = Yup.object().shape({
    eventTitle: eventTitleYupSchema,
    eventDate: dateOfBirthYupSchema,
    eventDescription: diaryEntryDescriptionYupSchema,
    eventLocation: locationYupSchema
  });

  const onLoadData = (images, vidoes, errors, editImages) => {
    if (errors) {
      ToastrSerivce({ type: 'error', message: errors });
    } else {
      setImagesToAdd(images.file);
      setVideosToAdd(vidoes.file);
      if (editImages) {
        let deletedImages = [];
        deletedImages = removeImages;
        deletedImages.push(editImages);
        setRemoveImages(deletedImages);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitData}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <CustomInputFields
              name="eventTitle"
              type="text"
              label="Event Title *"
              placeholder="Type your event title"
            />
            <CustomTextArea
              name="eventDescription"
              type="text"
              label="Event Description *"
              placeholder="Type your description here"
              rows="10"
              cols="50"
            />
            <DatePickerField
              name="eventDate"
              placeholderText="Event Date"
              label="Event Date *"
            />
            <CustomInputFields
              name="eventLocation"
              type="text"
              label="Event Location *"
              placeholder="Type your event location"
            />
            <MultipleImageUpload
              onLoadData={onLoadData}
              editStateImages={editStateEventImages}
            />
            <label>{LanguageHelper.EVENT_KIDS_ASSIGN_LABEL} *</label>
            <br />
            <ChildrenAvatarLoop
              onLoadSelectedChidData={onLoadSelectedChidData}
              editStateChildren={selectedChildrenData}
              edit={edit}
            />
            <button
              className="button primary-btn-green"
              type="submit"
              disabled={isSubmitting}
            >
              {edit
                ? LanguageHelper.SAVE_BTN_LABEL
                : LanguageHelper.ADD_EVENT_BTN_LABEL}
            </button>
          </form>
        )}
      </Formik>
      <h4>{showUploadMessage}</h4>
    </>
  );
};
AddEventForm.defaultProps = {
  edit: false,
  id: null,
  onCancel: null
};

AddEventForm.propTypes = {
  edit: PropTypes.bool,
  id: PropTypes.string,
  onCancel: PropTypes.func
};

export { AddEventForm };
