/* eslint-disable react/destructuring-assignment */
import { Formik } from 'formik';
import React from 'react';
import { CustomInputFields } from '../../common/commonFields';

const SearchBar = props => {
  return (
    <div className="d-none d-sm-block">
      <Formik
        initialValues={{ search: props.initiallValue }}
        onSubmit={props.onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <CustomInputFields
              name="search"
              type="text"
              label=""
              placeholder="Search"
              autoComplete="off"
              searchClass="form-control form-control-lg"
            />
            <button className="button primary-btn-green" type="submit" hidden>
              Search
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { SearchBar };
