/* eslint-disable react/destructuring-assignment */
import React from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import add from '../../../../assets/images/icons/add-green.svg';
import camera from '../../../../assets/images/camera-icon.svg';

const FileInputField = ({ label, ...props }) => {
  if (!isEmpty(props.src)) {
    if (isNil(props.src.key)) {
      return (
        <div className="upload-container">
          <label htmlFor="photo-upload" className="custom-file-upload fas">
            <div className="img-wrap img-upload">
              <img
                htmlFor="photo-upload"
                alt="profile_picture"
                src={props.src}
              />
            </div>
            <input id="photo-upload" type="file" onChange={props.onChange} />
            {!isNil(props.profileCard) ? (
              <img
                className="cfu-add-photo camera-add"
                alt="Add"
                src={camera}
              />
            ) : (
              <img className="cfu-add-photo" alt="Add" src={add} />
            )}
            {/* <img className="cfu-add-photo" alt="Add" src={add} /> */}

            {/* Camera Icon for Left Col - Profile Card */}
            {/* <img className="cfu-add-photo camera-add" alt="Add" src={camera} /> */}
          </label>
        </div>
      );
    }
  }
  return null;
};
FileInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  filetype: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
export { FileInputField };
