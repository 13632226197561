import React from 'react';
import moment from 'moment';

import isNil from 'lodash/isNil';
import { UserAvatar } from '../../UserAvatar';
import avatar from '../../../../assets/images/login.jpg';
import Icon from '../../../icomoon/icon';

import quoteIcon from '../../../../assets/images/dasboard/quote-icon.svg';

const DiaryEntryNotificationContent = props => {
  const { data } = props;
  // console.log(data.meta);
  return (
    <>
      {/* Notification - If there is unread notifications "notify-unread" this class need to be added to "notify-message-wrapper" div */}
      <div
        className={
          !data.seen
            ? 'notify-message-wrapper d-flex notification-unseen'
            : 'notify-message-wrapper d-flex'
        }
      >
        <UserAvatar
          size={53}
          shape="circle"
          src={avatar}
          style={{ marginRight: 15 }}
        />
        <div className="nm-right">
          <div className="d-flex align-items-center justify-content-between">
            <div className="nm-name">
              {!isNil(data.meta.created_by[0].first_name) &&
                `${data.meta.created_by[0].first_name} ${data.meta.created_by[0].last_name}`}
            </div>
            <div className="nm-date">
              {' '}
              {moment
                .utc(data.meta.post_created_at)
                .local()
                .fromNow()}
            </div>
          </div>
          <div className="nm-subtitle d-flex">
            {/* Icons - Only for desktop */}
            <img className="d-none d-sm-block" src={quoteIcon} alt="icon" />
            <span>Added Diary Entry to {data.meta.child_name}’s Timeline</span>
          </div>
          {/* This section need to be visibled with the quote and comment */}
          {/* <div className="nm-description">“Thank you for the evening, the party was great. We all had fun!!! The thank you gift Is greatly appreciated”</div> */}
        </div>
      </div>
    </>
  );
};

export { DiaryEntryNotificationContent };
