import React, { useState } from 'react';

import Icon from '../../../../icomoon/icon';

const DeleteMenuItem = props => {
  return (
    <div className="d-flex align-items-center">
      <Icon icon="icon-delete" size={25} color="#2EC99F" className="nl-icon" />
      <a>Delete</a>
    </div>
  );
};

export { DeleteMenuItem };
