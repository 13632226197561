import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { useDispatch } from 'react-redux';

import { AddDiaryEntryForm } from '../../../home/addDiaryEntry/AddDiaryEntryForm';
import { CommonMenuItem } from '../../commonMenuItem/CommonMenuItem';
import { PopupModal } from '../../popupModal/PopupModal';
import Icon from '../../../../icomoon/icon';
import { LanguageHelper } from '../../../../../helpers/languageHelpers/En';
import { getDiaryEntry } from '../../../../../api/userDiaryEntry/UserDiaryEntryApi';
import { addATimelineDataListSuccess } from '../../../../../features/timeLine/actions';

const DiaryEntryMenuItem = props => {
  const { type } = props;
  const dispatch = useDispatch();
  const [initailMenuItem, setMenuItem] = useState({
    diaryEntry: false
  });
  const addDiaryEntry = (value, data = null) => {
    setMenuItem({
      diaryEntry: value
    });
    if (data !== null) {
      getDiaryEntry(data._id)
        .then(response => {
          dispatch(addATimelineDataListSuccess(response.data));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div
        className="wel-btn-wrap"
        onClick={() => addDiaryEntry(true)}
        role="button"
      >
        {type !== 'timeline' ? (
          <CommonMenuItem
            buttonClass="nav-link nav-link-btn d-flex align-items-center"
            iconClass="bt-icon-wrapper icon-circle-45 icon-circle-green"
            title="Diary Entry"
            description="Share an entry on Timeline."
            iconComponent={
              <Icon
                icon="icon-diary-entry"
                size={20}
                color="#ffffff"
                className="nl-icon"
              />
            }
          />
        ) : (
          <div className="wel-btn wel-green-btn d-flex align-items-center">
            <div>
              <div className="bt-title">Diary Entry</div>
              <div className="bt-description">Share an entry on Timeline.</div>
            </div>
          </div>
        )}
        {/* Add Child Modal */}
      </div>
      <PopupModal
        closable={false}
        centered
        footer={null}
        visible={initailMenuItem.diaryEntry}
        onOk={() => addDiaryEntry(false)}
        onCancel={() => addDiaryEntry(false)}
        backBtn={false}
        closeBtn
        popupTitle={LanguageHelper.CREATE_DIARY_ENTRY}
        modalStyle="ant-modal default-modal"
        elementKey={uuid()}
      >
        <AddDiaryEntryForm onCancel={addDiaryEntry} />
      </PopupModal>
    </>
  );
};
DiaryEntryMenuItem.defaultProps = {
  type: null
};

DiaryEntryMenuItem.propTypes = {
  type: PropTypes.string
};
export { DiaryEntryMenuItem };
