/* eslint-disable no-useless-catch */
import * as ApiConnector from '../ApiConnector';
import { KidsAppHelper, KidsAppHelperPost } from '../../helpers/KidsAppHelper';
import {
  getUserChildrenList,
  getUserChildrenListSuccess,
  getUserChildrenListError
} from '../../features/userChildren/actions';
import {
  getOtherChildrenList,
  getOtherChildrenListSuccess,
  getOtherChildrenListError
} from '../../features/otherChildren/actions';
import {
  getTimelineDataList,
  getTimelineDataListSuccess,
  getTimelineDataListError
} from '../../features/timeLine/actions';

// eslint-disable-next-line consistent-return
export const addChild = async payload => {
  try {
    const { data } = await ApiConnector.post(KidsAppHelperPost.child, payload);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// eslint-disable-next-line consistent-return
export const childImageUpload = async id => {
  try {
    const { data } = await ApiConnector.post(
      `${KidsAppHelperPost.addChildImage}/${id}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// api to get user children
export const getUserChildren = userId => {
  return dispatch => {
    dispatch(getUserChildrenList());
    ApiConnector.get(`${KidsAppHelperPost.myChild}/${userId}`)
      .then(response => {
        if (response.data) {
          dispatch(getUserChildrenListSuccess(response.data));
        } else {
          // Internal server error
          dispatch(getUserChildrenListError(response.data.message));
        }
      })
      .catch(exception => {
        dispatch(getUserChildrenListError(exception));
        console.log('response error');
      });
  };
};

// api to get user children
export const getOtherChildren = userId => {
  return dispatch => {
    dispatch(getOtherChildrenList());
    ApiConnector.get(`${KidsAppHelperPost.otherChild}/${userId}`)
      .then(response => {
        if (response.data) {
          dispatch(getOtherChildrenListSuccess(response.data));
        } else {
          // Internal server error
          dispatch(getOtherChildrenListError(response.data.message));
        }
      })
      .catch(exception => {
        dispatch(getOtherChildrenListError(exception));
        console.log('response error');
      });
  };
};

// api to get user children
export const getAChild = async childId => {
  try {
    const { data } = await ApiConnector.get(
      `${KidsAppHelperPost.child}/${childId}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// api to update user children
export const updateChild = async (payload, childId) => {
  try {
    const { data } = await ApiConnector.put(
      `${KidsAppHelperPost.child}/${childId}`,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

// api to get all child posts
export const getChildAllPosts = (childId, params) => {
  return dispatch => {
    dispatch(getTimelineDataList());
    ApiConnector.get(`${KidsAppHelperPost.post}/${childId}`, { params })
      .then(response => {
        if (response.data) {
          dispatch(getTimelineDataListSuccess(response.data, childId));
        } else {
          // Internal server error
          dispatch(getTimelineDataListError(response.data.message));
        }
      })
      .catch(exception => {
        dispatch(getTimelineDataListError(exception));
        console.log('response error');
      });
  };
};

// api to update user children
export const getParent = async childId => {
  try {
    const { data } = await ApiConnector.get(
      `${KidsAppHelperPost.familyMembers}/${childId}`
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
