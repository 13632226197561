import * as ApiConnector from '../ApiConnector';
import { KidsAppHelperPost } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const addMedia = async (payload, edit, id) => {
  try {
    if (edit) {
      const { data } = await ApiConnector.put(
        `${KidsAppHelperPost.post}/${id}`,
        payload
      );
      return data;
    }
    const { data } = await ApiConnector.post(KidsAppHelperPost.media, payload);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const getMedia = async id => {
  try {
    const { data } = await ApiConnector.get(`${KidsAppHelperPost.media}/${id}`);
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const deleteMedia = async id => {
  try {
    const { data } = await ApiConnector.del(
      `${KidsAppHelperPost.media}/${id}`,
      { id }
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};

export const deletePost = async id => {
  try {
    const { data } = await ApiConnector.del(`${KidsAppHelperPost.post}/${id}`, {
      id
    });
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
