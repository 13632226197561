/* -------------------------------------------------------------
 * unique key namespace used by combineReducers.
 * By convention it will match the directory structure to
 * make it easy to locate the src.
 * Also action types will prefix with the capitalized version
 * -------------------------------------------------------------
 */

export const key = 'otherChildren';

/* -----------------------------
 * Actions
 * -----------------------------
 */

// retrieve filter data
export const GET_OTHER_CHILDREN_LIST = 'GET_OTHER_CHILDREN_LIST';
export const GET_OTHER_CHILDREN_LIST_SUCCESS =
  'GET_OTHER_CHILDREN_LIST_SUCCESS';
export const GET_OTHER_CHILDREN_LIST_ERROR = 'GET_OTHER_CHILDREN_LIST_ERROR';

// add a child from invitations
export const ADD_OTHER_CHILD_BY_INVITATION = 'ADD_OTHER_CHILD_BY_INVITATION';
export const ADD_OTHER_CHILD_BY_INVITATION_SUCCESS =
  'ADD_OTHER_CHILD_BY_INVITATION_SUCCESS';
export const ADD_OTHER_CHILD_BY_INVITATION_ERROR =
  'ADD_OTHER_CHILD_BY_INVITATION_ERROR';

// export const GET_SINGLE_ACTION_ITEM_LIST = 'GET_SINGLE_ACTION_ITEM_LIST';
// export const GET_SINGLE_ACTION_ITEM_LIST_SUCCESS =
//   'GET_SINGLE_ACTION_ITEM_LIST_SUCCESS';
// export const GET_SINGLE_ACTION_ITEM_LIST_ERROR =
//   'GET_SINGLE_ACTION_ITEM_LIST_ERROR';

export const actionTypes = {
  GET_OTHER_CHILDREN_LIST,
  GET_OTHER_CHILDREN_LIST_SUCCESS,
  GET_OTHER_CHILDREN_LIST_ERROR

  //   GET_SINGLE_ACTION_ITEM_LIST,
  //   GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  //   GET_SINGLE_ACTION_ITEM_LIST_ERROR
};

/* -----------------------------
 * Action Creators
 * -----------------------------
 */
export const getOtherChildrenList = request => ({
  type: GET_OTHER_CHILDREN_LIST,
  payload: request
});

export const getOtherChildrenListSuccess = response => ({
  type: GET_OTHER_CHILDREN_LIST_SUCCESS,
  payload: response
});

export const getOtherChildrenListError = error => ({
  type: GET_OTHER_CHILDREN_LIST_ERROR,
  payload: error
});

export const addOtherChildByInvitation = request => ({
  type: ADD_OTHER_CHILD_BY_INVITATION,
  payload: request
});

export const addOtherChildByInvitationSuccess = request => ({
  type: ADD_OTHER_CHILD_BY_INVITATION_SUCCESS,
  payload: request
});

export const addOtherByInvitationError = error => ({
  type: ADD_OTHER_CHILD_BY_INVITATION_ERROR,
  payload: error
});
// export const getSingleActionItemList = request => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST,
//   payload: request
// });

// export const getSingleActionItemListSuccess = response => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
//   payload: response
// });

// export const getSingleActionItemListError = error => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_ERROR,
//   payload: error
// });

export const actions = {
  getOtherChildrenList,
  getOtherChildrenListSuccess,
  getOtherChildrenListError

  //   getSingleActionItemList,
  //   getSingleActionItemListSuccess,
  //   getSingleActionItemListError
};
