/* -------------------------------------------------------------
 * unique key namespace used by combineReducers.
 * By convention it will match the directory structure to
 * make it easy to locate the src.
 * Also action types will prefix with the capitalized version
 * -------------------------------------------------------------
 */

export const key = 'timeLine';

/* -----------------------------
 * Actions
 * -----------------------------
 */

// retrieve filter data
export const GET_TIMELINE_DATA_LIST = 'GET_TIMELINE_DATA_LIST';
export const GET_TIMELINE_DATA_LIST_SUCCESS = 'GET_TIMELINE_DATA_LIST_SUCCESS';
export const GET_TIMELINE_DATA_LIST_ERROR = 'GET_TIMELINE_DATA_LIST_ERROR';

// add new data
export const ADD_TIMELINE_DATA_LIST = 'ADD_TIMELINE_DATA_LIST';
export const ADD_TIMELINE_DATA_LIST_SUCCESS = 'ADD_TIMELINE_DATA_LIST_SUCCESS';
export const ADD_TIMELINE_DATA_LIST_ERROR = 'ADD_TIMELINE_DATA_LIST_ERROR';

export const DELETE_TIMELINE_DATA_LIST_SUCCESS =
  'DELETE_TIMELINE_DATA_LIST_SUCCESS';

// export const GET_SINGLE_ACTION_ITEM_LIST = 'GET_SINGLE_ACTION_ITEM_LIST';
// export const GET_SINGLE_ACTION_ITEM_LIST_SUCCESS =
//   'GET_SINGLE_ACTION_ITEM_LIST_SUCCESS';
// export const GET_SINGLE_ACTION_ITEM_LIST_ERROR =
//   'GET_SINGLE_ACTION_ITEM_LIST_ERROR';

export const actionTypes = {
  GET_TIMELINE_DATA_LIST,
  GET_TIMELINE_DATA_LIST_SUCCESS,
  GET_TIMELINE_DATA_LIST_ERROR,
  ADD_TIMELINE_DATA_LIST,
  ADD_TIMELINE_DATA_LIST_SUCCESS,
  ADD_TIMELINE_DATA_LIST_ERROR,
  DELETE_TIMELINE_DATA_LIST_SUCCESS

  //   GET_SINGLE_ACTION_ITEM_LIST,
  //   GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
  //   GET_SINGLE_ACTION_ITEM_LIST_ERROR
};

/* -----------------------------
 * Action Creators
 * -----------------------------
 */
export const getTimelineDataList = request => ({
  type: GET_TIMELINE_DATA_LIST,
  payload: request
});

export const getTimelineDataListSuccess = (response, childId) => ({
  type: GET_TIMELINE_DATA_LIST_SUCCESS,
  payload: response,
  childId
});

export const getTimelineDataListError = error => ({
  type: GET_TIMELINE_DATA_LIST_ERROR,
  payload: error
});

export const addATimelineDataList = request => ({
  type: ADD_TIMELINE_DATA_LIST,
  payload: request
});

export const addATimelineDataListSuccess = response => ({
  type: ADD_TIMELINE_DATA_LIST_SUCCESS,
  payload: response
});

export const addATimelineDataListError = error => ({
  type: ADD_TIMELINE_DATA_LIST_ERROR,
  payload: error
});

export const deleteATimelineDataListSuccess = response => ({
  type: DELETE_TIMELINE_DATA_LIST_SUCCESS,
  payload: response
});

// export const getSingleActionItemList = request => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST,
//   payload: request
// });

// export const getSingleActionItemListSuccess = response => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_SUCCESS,
//   payload: response
// });

// export const getSingleActionItemListError = error => ({
//   type: GET_SINGLE_ACTION_ITEM_LIST_ERROR,
//   payload: error
// });

export const actions = {
  getTimelineDataList,
  getTimelineDataListSuccess,
  getTimelineDataListError

  //   getSingleActionItemList,
  //   getSingleActionItemListSuccess,
  //   getSingleActionItemListError
};
