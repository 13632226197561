import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getMyChild } from '../../../../api/userRegistration/UserRegistrationApi';
import { CustomInputFields, DropDownField } from '../../../common/commonFields';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import { UserAvatar } from '../../UserAvatar';
import { sendInvitation } from '../../../../api/invitation/InvitationApi';
import { getSingleData } from '../../../../config/localStorage';
import { emailYupSchema } from '../../../common/commonFields/formValidationSchema/FormValidationSchema';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';

const validationSchema = Yup.object().shape({
  email: emailYupSchema
});

const InviteToFamliyForm = props => {
  const { onCancel } = props;
  let childArray = [];
  // let avatarDiv = '';

  const divStyle = {
    border: '3px solid #2EC99F',
    borderRadius: '50%',
    width: 'fit-content'
  };
  const history = useHistory();
  const [myChildren, setMyChildren] = useState([]);
  const [childId, setChildId] = useState([]);
  const [avatarDiv, setAvatarDiv] = useState();
  const [initialError, setError] = useState(false);

  const optionList = [
    { value: 'uncle', key: 1, label: 'Uncle' },
    { value: 'grandmother', key: 2, label: 'Grand Mother' },
    { value: 'aunt', key: 3, label: 'Aunt' },
    { value: 'grandfather', key: 4, label: 'Grand Father' },
    { value: 'closefriend', key: 5, label: 'Close Friend' },
    { value: 'nephew', key: 6, label: 'Nephew' }
  ];

  useEffect(() => {
    getMyChild(JSON.parse(getSingleData('current-user'))._id)
      .then(response => {
        if (response.success) {
          setMyChildren(response.data.children);
          childArray.push(response.data.children[0].id);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (myChildren.length > 0) {
      setAvatarDiv(
        myChildren.map((data, index) => (
          <div key={index} onClick={() => imageClick(data.id)} role="button">
            <UserAvatar
              key={index}
              size={42}
              shape="circle"
              src={data.child_image}
            />
          </div>
        ))
      );
    }
  }, [myChildren.length > 0]);

  // call api to add child
  const submitData = values => {
    if (childId.length === 0) {
      setError({ message: 'Please select one or more children' });
    } else {
      setError(false);
      const partnerInvitation = {
        from: JSON.parse(getSingleData('current-user'))._id,
        to_email: values.email,
        to_mobile: null,
        invitation_type: 'adult',
        child_ids: childId,
        relationship: values.relationship
      };

      sendInvitation(partnerInvitation)
        .then(response => {
          if (response.success) {
            ToastrSerivce({ type: 'success', message: response.message });
            onCancel(false);
          } else {
            ToastrSerivce({ type: 'error', message: response.message });
          }
        })
        .catch(error => {
          console.log(error);
          ToastrSerivce({ type: 'error', message: error.message });
        });
    }
  };
  const imageClick = id => {
    childArray = childId;
    if (childArray.indexOf(id) > -1) {
      childArray.splice(childArray.indexOf(id), 1);
    } else {
      childArray.push(id);
    }
    setChildId(childArray);
    setAvatarDiv(
      myChildren.map((data, index) => (
        <div
          key={index}
          onClick={() => imageClick(data.id)}
          role="button"
          style={childArray.indexOf(data.id) > -1 ? divStyle : null}
        >
          <UserAvatar
            key={index}
            size={42}
            shape="circle"
            src={data.child_image}
          />
        </div>
      ))
    );
  };

  return (
    <>
      <div className="send-invitation-wrapper">
        <p>{LanguageHelper.SEND_INVITE_DESCRIPTION_1}</p>

        <Formik
          initialValues={{ email: '', relationship: 'uncle' }}
          validationSchema={validationSchema}
          onSubmit={submitData}
        >
          {({ handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <CustomInputFields
                name="email"
                type="email"
                label="Email *"
                placeholder="Enter Email"
              />

              <DropDownField
                id="relationship"
                name="relationship"
                placeholder="Select Relationship"
                label="Relationship *"
                options={optionList}
                onChange={handleChange}
              />

              <p>{LanguageHelper.SEND_INVITE_DESCRIPTION_2}</p>
              <div className="d-flex align-items-center flex-wrap avatar-group-wrapper">
                {avatarDiv}
              </div>
              {initialError ? (
                <div className="input-error">{initialError.message}</div>
              ) : null}
              <button className="button primary-btn-green" type="submit">
                {LanguageHelper.SEND_EMAIL_BTN_LABLE}
              </button>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

InviteToFamliyForm.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export { InviteToFamliyForm };
