import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const ToastrSerivce = props => {
  switch (props.type) {
    case 'success':
      toast.success(props.message, { position: toast.POSITION.TOP_RIGHT });
      break;
    case 'error':
      toast.error(props.message, { position: toast.POSITION.TOP_RIGHT });
      break;
    case 'info':
      toast.info(props.message, { position: toast.POSITION.TOP_RIGHT });
      break;
    case 'warrning':
      toast.warning(props.message, { position: toast.POSITION.TOP_RIGHT });
      break;
    default:
      toast(props.message, { position: toast.POSITION.TOP_RIGHT });
  }
  return toast(props.msg);
};

export default ToastrSerivce;
