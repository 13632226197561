import React, { useState } from 'react';

import { CommonMenuItem } from '../../commonMenuItem/CommonMenuItem';
import Icon from '../../../../icomoon/icon';

const ManageChildrenMeunItem = () => {
  const [initailMenuItem, setMenuItem] = useState({
    createEvent: false
  });
  const createEvent = value => {
    setMenuItem({
      createEvent: value
    });
  };

  return (
    <div>
      <div>
        <CommonMenuItem
          buttonClass="nav-link nav-link-btn d-flex align-items-center"
          iconClass="bt-icon-wrapper icon-circle-45 icon-circle-light-green"
          title="Manage Children "
          description=""
          iconComponent={
            <Icon
              icon="icon-children"
              size={25}
              color="#2EC99F"
              className="nl-icon"
            />
          }
        />
      </div>
      {/* <PopupModal
        closable={false}
        centered
        footer={null}
        visible={initailMenuItem.createEvent}
        onOk={() => createEvent(false)}
        onCancel={() => createEvent(false)}
        backBtn
        closeBtn
        modalStyle="ant-modal default-modal"
      >
        <AddEventForm />
      </PopupModal> */}
    </div>
  );
};

export { ManageChildrenMeunItem };
