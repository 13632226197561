import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../icomoon/icon';
import { awsGetASingleImage } from '../../../../api/signUrl/AWSSignUrl';

const MultipleImageUpload = props => {
  const { editStateImages, onLoadData } = props;
  const [editImages, setEditImages] = useState([]);
  const [loadImages, setLoadImages] = useState(editStateImages);

  const files = [];
  editStateImages.map(data => files.push({ key: data.content }));
  useEffect(() => {
    awsGetASingleImage({
      files,
      location: 'post-images',
      size: 'small'
    })
      .then(response => {
        if (response.data) {
          setEditImages(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [editStateImages]);

  const [images, setImages] = useState({
    file: []
  });

  const [videos, setVideos] = useState({
    file: []
  });

  const [imagesPreviewUrl, setImagesPreviewUrl] = useState({
    file: []
  });

  const [videosPreviewUrl, setVideosPreviewUrl] = useState({
    file: []
  });

  const [initialError, setError] = useState(false);
  const IMAGE_FILE_SIZE = 5 * 1024 * 1024;
  const VIDEO_FILE_SIZE = 20 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/mov',
    'video/avi'
  ];
  const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/mov', 'video/avi'];

  const fileObj = [];
  let imagefileArray = [];
  let videofileArray = [];
  let imageFilePreviewUrlArray = [];
  let videoFilePreviewUrlArray = [];

  const removeImage = param => {
    const index = imagesPreviewUrl.file.indexOf(param);
    const previewImages = imagesPreviewUrl;
    const imageFiles = images;

    if (imagesPreviewUrl.file.indexOf(param) > -1) {
      previewImages.file.splice(imagesPreviewUrl.file.indexOf(param), 1);
      setImagesPreviewUrl({ file: previewImages.file });
      imageFiles.file.splice(index, 1);
      setImages({ file: imageFiles.file });
      onLoadData(images, videos, initialError, null);
    }
  };

  const removeEditImage = param => {
    const previewImages = editImages;
    previewImages.splice(param, 1);
    const img = [];
    previewImages.map((data, index) => img.push(data));
    setEditImages(img);
    onLoadData(images, videos, initialError, editStateImages[param]._id);
    editStateImages.splice(param, 1);
  };

  const removeVideo = param => {
    const index = videosPreviewUrl.file.indexOf(param);
    const previewVideos = videosPreviewUrl;
    const videoFiles = videos;

    if (videosPreviewUrl.file.indexOf(param) > -1) {
      previewVideos.file.splice(videosPreviewUrl.file.indexOf(param), 1);
      setVideosPreviewUrl({ file: previewVideos.file });

      videoFiles.file.splice(index, 1);
      setVideos({ file: videoFiles.file });

      onLoadData(images, videos, initialError, null);
    }
  };

  const uploadMultipleFiles = e => {
    imageFilePreviewUrlArray = imagesPreviewUrl.file;
    videoFilePreviewUrlArray = videosPreviewUrl.file;
    imagefileArray = images.file;
    videofileArray = videos.file;
    fileObj.push(e.target.files);
    let message = '';
    for (let i = 0; i < fileObj[0].length; i++) {
      message = '';
      if (!SUPPORTED_FORMATS.includes(fileObj[0][i].type)) {
        message = 'Only JPG/JPGE and PNG allowed';
      } else if (SUPPORTED_VIDEO_FORMATS.includes(fileObj[0][i].type)) {
        if (fileObj[0][i].size > VIDEO_FILE_SIZE) {
          message = 'video size is too large to upload';
        } else {
          videoFilePreviewUrlArray.push(URL.createObjectURL(fileObj[0][i]));
          videofileArray.push(fileObj[0][i]);
        }
      } else if (SUPPORTED_IMAGE_FORMATS.includes(fileObj[0][i].type)) {
        if (fileObj[0][i].size > IMAGE_FILE_SIZE) {
          message = 'image size is too large to upload';
        } else {
          imageFilePreviewUrlArray.push(URL.createObjectURL(fileObj[0][i]));
          imagefileArray.push(fileObj[0][i]);
        }
      }
    }
    setImagesPreviewUrl({ file: imageFilePreviewUrlArray });
    setVideosPreviewUrl({ file: videoFilePreviewUrlArray });
    setImages({ file: imagefileArray });
    setVideos({ file: videofileArray });
    onLoadData(images, videos, message);
  };
  return (
    <div>
      <label>Upload Media *</label>

      <div className="upload-media-wrapper d-flex flex-wrap">
        {/* image thumbnail */}
        {(imagesPreviewUrl.file || []).map((url, index) => (
          <div className="uploaded-img-thum" key={`img${index}`}>
            <div
              className="remove-img-icon"
              onClick={() => removeImage(url)}
              role="button"
            >
              <Icon
                icon="icon-close"
                size={7}
                color="#7C7C7C"
                className="nl-icon"
              />
            </div>
            <img src={url} alt="..." />
          </div>
        ))}
        {(editImages || []).map((data, index) => (
          <div className="uploaded-img-thum" key={`imgs${index}`}>
            <div
              className="remove-img-icon"
              onClick={() => removeEditImage(index)}
              role="button"
            >
              <Icon
                icon="icon-close"
                size={7}
                color="#7C7C7C"
                className="nl-icon"
              />
            </div>
            <img src={data.url} alt="..." />
          </div>
        ))}
        {/* video thumbnail */}
        {(videosPreviewUrl.file || []).map((url, index) => (
          <div className="uploaded-img-thum" key={`video${index}`}>
            <div
              className="remove-img-icon"
              onClick={() => removeVideo(url)}
              role="button"
            >
              <Icon
                icon="icon-close"
                size={7}
                color="#7C7C7C"
                className="nl-icon"
              />
            </div>
            <img src={url} alt="..." />
          </div>
        ))}

        <label
          htmlFor="photo-upload-multiple"
          className="custom-file-upload multi-photo-uploader-wrapper fas"
        >
          <input
            id="photo-upload-multiple"
            type="file"
            className="form-control"
            onChange={uploadMultipleFiles}
            multiple
          />
          <Icon
            icon="icon-add-photo"
            size={36}
            color="#2EC99F"
            className="nl-icon"
          />
        </label>
      </div>
    </div>
  );
};
MultipleImageUpload.defaultProps = {
  editStateImages: []
};

MultipleImageUpload.propTypes = {
  editStateImages: PropTypes.array,
  onLoadData: PropTypes.func.isRequired
};

export { MultipleImageUpload };
