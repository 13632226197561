import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
// import "react-responsive-carousel/lib/styles/carousel.min.css";

import slide01 from '../../assets/images/home/slide-01.png';
import slide02 from '../../assets/images/home/slide-02.png';
import slide03 from '../../assets/images/home/slide-03.png';
import slide04 from '../../assets/images/home/slide-04.png';
import slide05 from '../../assets/images/home/slide-05.png';

import './style.css';

const ImageCarousel = props => {
  const {
    showIndicators,
    axis,
    showArrows,
    showThumbs,
    showStatus,
    statusFormatter,
    renderArrowPrev,
    renderArrowNext,
    renderIndicator,
    children,
    location
  } = props;

  return (
    <>
      {location !== 'dashboard' ? (
        <Carousel
          // className="carousel-wrapper"
          axis={axis}
          showThumbs={showThumbs}
          showArrows={showArrows}
          showStatus={showStatus}
          showIndicators={showIndicators}
          statusFormatter={statusFormatter}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          renderIndicator={renderIndicator}
        >
          {children}
        </Carousel>
      ) : (
        <Carousel
          className="carousel-wrapper"
          axis={axis}
          showThumbs={showThumbs}
          showArrows={showArrows}
          showStatus={showStatus}
          showIndicators={showIndicators}
          statusFormatter={statusFormatter}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          transitionTime={2000}
          infiniteLoop
          autoPlay
        >
          {children}
        </Carousel>
      )}
    </>
  );
};

ImageCarousel.defaultProps = {
  axis: 'horizontal',
  showThumbs: false,
  showArrows: false,
  showStatus: false,
  showIndicators: true,
  statusFormatter: () => {},
  renderArrowPrev: () => {},
  renderArrowNext: () => {},
  renderIndicator: () => {}
};

ImageCarousel.propTypes = {
  axis: PropTypes.string,
  location: PropTypes.string.isRequired,
  showThumbs: PropTypes.bool,
  showArrows: PropTypes.bool,
  showStatus: PropTypes.bool,
  showIndicators: PropTypes.bool,
  statusFormatter: PropTypes.func,
  renderArrowNext: PropTypes.func,
  renderIndicator: PropTypes.func,
  renderArrowPrev: PropTypes.func,
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ImageCarousel;
