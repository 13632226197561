import React from 'react';
import { TitleLayout } from './TitleLayout';

const WelcomeLayout = props => {
  let layOutBgImage = 'outer-container bg-image-welcome';

  if (props.children[1].props.location.pathname === '/add-child') {
    layOutBgImage = 'outer-container bg-image-addChild';
  } else if (props.children[1].props.location.pathname === '/add-picture') {
    layOutBgImage = 'outer-container bg-image-addPicture';
  } else if (props.children[1].props.location.pathname === '/other-half') {
    layOutBgImage = 'outer-container bg-image-otherHalf';
  } else if (props.children[1].props.location.pathname === '/send-invitation') {
    layOutBgImage = 'outer-container bg-image-sendInvitation';
  } else if (props.children[1].props.location.pathname === '/invitation-sent') {
    layOutBgImage = 'outer-container bg-image-invitationSent';
  } else if (
    props.children[1].props.location.pathname === '/accept-invite-otp'
  ) {
    layOutBgImage = 'outer-container bg-image-acceptInviteOtp';
  } else if (props.children[1].props.location.pathname === '/accept-invite') {
    layOutBgImage = 'outer-container bg-image-acceptInvite';
  } else if (
    props.children[1].props.location.pathname ===
    '/accept-invite-congratulations'
  ) {
    layOutBgImage = 'outer-container bg-image-acceptInviteCongratulations';
  }

  return (
    <>
      <div className="welcome-navbar">
        <TitleLayout />
      </div>
      <div className={layOutBgImage}>
        <div className="container-small">
          <div className="container-inner">
            <div className="welcome-box-right">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WelcomeLayout };
