/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import reverse from 'lodash/reverse';
import last from 'lodash/last';
import size from 'lodash/size';
import { useHistory } from 'react-router-dom';
import uuid from 'uuid/v4';

import { Drawer } from 'antd';
import isNil from 'lodash/isNil';
import { EditOptionsMenu } from '../../EditOptionsMenu';
import { AlbumNotificationContent } from './AlbumNotificationContent';
import { EventNotificationContent } from './EventNotificationContent';
import { DiaryEntryNotificationContent } from './DiaryEntryNotificationContent';
import { getSingleData } from '../../../../config/localStorage';
import firebase from '../../../../config/firebase';
import {
  ACCEPT_INVITATION_NOTIFICTION_TYPE,
  ALBUM_NOTIFICTION_TYPE,
  DIARY_ENTRY_NOTIFICTION_TYPE,
  EVENT_NOTIFICTION_TYPE,
  RECEIVED_INVITATION_NOTIFICTION_TYPE
} from '../../../../helpers/AppConstantsHelper';
import { AcceptInvitationNotificationContent } from './AcceptInvitationNotificationContent';
import { ReceiveInvitationNotificationContent } from './ReceiveInvitationNotificationContent';

const NotificationDrawer = props => {
  const {
    className,
    placement,
    onClose,
    visible,
    closable,
    setNotificationCount
  } = props;
  const [notifications, setNotifications] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [allKeys, setAllKeys] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [all, setAll] = useState(true);
  const page = 15;
  const history = useHistory();

  useEffect(() => {
    console.log('here');
    if (all) {
      firebase
        .database()
        .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
        .orderByKey()
        .limitToLast(page)
        .on('value', snapshot => {
          const data = snapshot.val();
          console.log('firebase', data);
          if (data) {
            const notificationArray = Object.keys(data).map(i => data[i]);
            const notificationKeyArray = Object.keys(data);
            const reverseArray = reverse(notificationArray);
            const reverseKeyArray = reverse(notificationKeyArray);
            setLastKey(last(reverseKeyArray));
            setNotifications(reverseArray);
            setAllKeys(reverseKeyArray);
          }
        });
    } else {
      firebase
        .database()
        .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
        .orderByChild('seen')
        .equalTo(false)
        .limitToLast(page)
        .on('value', snapshot => {
          const data = snapshot.val();
          console.log('unread', data);
          if (!isNil(data)) {
            const notificationArray = Object.keys(data).map(i => data[i]);
            const notificationKeyArray = Object.keys(data);
            const reverseArray = reverse(notificationArray);
            const reverseKeyArray = reverse(notificationKeyArray);
            setLastKey(last(reverseKeyArray));
            setNotifications(reverseArray);
            setAllKeys(reverseKeyArray);
          } else {
            setNotifications([]);
          }
        });
    }

    firebase
      .database()
      .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
      .orderByChild('seen')
      .equalTo(false)
      .on('value', snapshot => {
        const data = snapshot.val();
        if (!isNil(data)) {
          console.log('firebase', data);
          const notificationArray = Object.keys(data).map(i => data[i]);
          setNotificationCount(size(notificationArray));
        } else {
          setNotificationCount(0);
        }
      });
  }, []);

  const fetchMoreData = () => {
    console.log('scrolled bottomssss');
    console.log('asdassd', lastKey);
    if (lastKey !== null) {
      if (all) {
        firebase
          .database()
          .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
          .orderByKey()
          .endAt(lastKey)
          .limitToLast(page + 1)
          .on('value', snapshot => {
            const data = snapshot.val();
            console.log('firebase2', data);

            const notificationArray = Object.keys(data).map(i => data[i]);
            const notificationKeyArray = Object.keys(data);
            notificationArray.pop();
            notificationKeyArray.pop();
            const reverseArray = reverse(notificationArray);
            const reverseKeyArray = reverse(notificationKeyArray);
            setLastKey(last(reverseKeyArray));
            const allNotifications = notifications.concat(reverseArray);
            const allNotificationsKeys = allKeys.concat(reverseKeyArray);
            setNotifications(allNotifications);
            setAllKeys(allNotificationsKeys);
          });
      } else {
        firebase
          .database()
          .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
          .orderByChild('seen')
          .equalTo(false)
          .limitToLast(page)
          .on('value', snapshot => {
            const data = snapshot.val();
            if (!isNil(data)) {
              const notificationArray = Object.keys(data).map(i => data[i]);
              const notificationKeyArray = Object.keys(data);
              notificationArray.pop();
              notificationKeyArray.pop();
              const reverseArray = reverse(notificationArray);
              const reverseKeyArray = reverse(notificationKeyArray);
              setLastKey(last(reverseKeyArray));
              const allNotifications = notifications.concat(reverseArray);
              const allNotificationsKeys = allKeys.concat(reverseKeyArray);
              setNotifications(allNotifications);
              setAllKeys(allNotificationsKeys);
            }
          });
      }
    }
  };

  const clickAll = useCallback(() => {
    setAll(true);
    firebase
      .database()
      .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
      .orderByKey()
      .limitToLast(page)
      .on('value', snapshot => {
        const Alldata = snapshot.val();
        console.log('firebase', Alldata);

        const notificationArray = Object.keys(Alldata).map(i => Alldata[i]);
        const notificationKeyArray = Object.keys(Alldata);
        const reverseArray = reverse(notificationArray);
        const reverseKeyArray = reverse(notificationKeyArray);
        setLastKey(last(reverseKeyArray));
        setNotifications(reverseArray);
        setAllKeys(reverseKeyArray);
      });
  });
  const clickUnread = useCallback(() => {
    setAll(false);
    firebase
      .database()
      .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
      .orderByChild('seen')
      .equalTo(false)
      .limitToLast(page)
      .on('value', snapshot => {
        const data = snapshot.val();
        console.log('unread', data);
        if (!isNil(data)) {
          const notificationArray = Object.keys(data).map(i => data[i]);
          const notificationKeyArray = Object.keys(data);
          const reverseArray = reverse(notificationArray);
          const reverseKeyArray = reverse(notificationKeyArray);
          setLastKey(last(reverseKeyArray));
          setNotifications(reverseArray);
          setAllKeys(reverseKeyArray);
        } else {
          setNotifications([]);
        }
      });
  });
  const clickMarkAsRead = useCallback(() => {
    firebase
      .database()
      .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
      .orderByChild('seen')
      .equalTo(false)
      .on('value', snapshot => {
        const data = snapshot.val();
        console.log('unread', data);
        if (!isNil(data)) {
          const notificationKeyArray = Object.keys(data);
          const childDataToUpdate = {};
          notificationKeyArray.map((arr, index) => {
            console.log(arr);
            const subElementReference = `notifications/${
              JSON.parse(getSingleData('current-user'))._id
            }/${arr}/seen`;
            childDataToUpdate[subElementReference] = true;
            if (index === size(notificationKeyArray) - 1) {
              return firebase
                .database()
                .ref()
                .update(childDataToUpdate);
            }
          });
        }
      });
  });

  const notificationClick = (dataObject, type, index) => {
    console.log(dataObject, allKeys[index]);
    const childDataToUpdate = {};
    firebase
      .database()
      .ref(`notifications/${JSON.parse(getSingleData('current-user'))._id}`)
      .on('value', snapshot => {
        const data = snapshot.val();
        console.log(data);
        const subElementReference = `notifications/${
          JSON.parse(getSingleData('current-user'))._id
        }/${allKeys[index]}/seen`;
        childDataToUpdate[subElementReference] = true;
        firebase
          .database()
          .ref()
          .update(childDataToUpdate);
      });
    // history.push(`/profile/${JSON.parse(getSingleData('current-user'))._id}`);
    if (type === ALBUM_NOTIFICTION_TYPE) {
      history.push(`/album/${dataObject.meta.post_id}`);
    } else if (type === EVENT_NOTIFICTION_TYPE) {
      history.push(`/event/${dataObject.meta.post_id}`);
    } else if (type === DIARY_ENTRY_NOTIFICTION_TYPE) {
      history.push(`/diary-entry/${dataObject.meta.post_id}`);
    }
  };
  return (
    <Drawer
      className={className}
      placement={placement}
      closable={closable}
      onClose={onClose}
      visible={visible}
    >
      <div className="drawer-header d-flex align-items-center justify-content-between">
        <div className="drawer-title">Notifications</div>
        <EditOptionsMenu
          clickAll={clickAll}
          clickUnread={clickUnread}
          clickMarkAsRead={clickMarkAsRead}
        />
      </div>
      <div id="scrollableDiv" style={{ height: '100%', overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
        >
          {notifications.map((data, index) => {
            if (data.type === ALBUM_NOTIFICTION_TYPE) {
              return (
                <div
                  onClick={() =>
                    notificationClick(data, ALBUM_NOTIFICTION_TYPE, index)
                  }
                  role="button"
                  key={uuid()}
                >
                  <AlbumNotificationContent data={data} key={uuid()} />
                </div>
              );
            }
            if (data.type === EVENT_NOTIFICTION_TYPE) {
              return (
                <div
                  onClick={() =>
                    notificationClick(data, EVENT_NOTIFICTION_TYPE, index)
                  }
                  role="button"
                  key={uuid()}
                >
                  <EventNotificationContent data={data} key={uuid()} />
                </div>
              );
            }
            if (data.type === DIARY_ENTRY_NOTIFICTION_TYPE) {
              return (
                <div
                  onClick={() =>
                    notificationClick(data, DIARY_ENTRY_NOTIFICTION_TYPE, index)
                  }
                  role="button"
                  key={uuid()}
                >
                  <DiaryEntryNotificationContent data={data} key={uuid()} />
                </div>
              );
            }
            if (data.type === ACCEPT_INVITATION_NOTIFICTION_TYPE) {
              return (
                <div
                  onClick={() =>
                    notificationClick(
                      data,
                      ACCEPT_INVITATION_NOTIFICTION_TYPE,
                      index
                    )
                  }
                  role="button"
                  key={uuid()}
                >
                  <AcceptInvitationNotificationContent
                    data={data}
                    key={uuid()}
                  />
                </div>
              );
            }
            if (data.type === RECEIVED_INVITATION_NOTIFICTION_TYPE) {
              return (
                <div
                  onClick={() =>
                    notificationClick(
                      data,
                      RECEIVED_INVITATION_NOTIFICTION_TYPE,
                      index
                    )
                  }
                  role="button"
                  key={uuid()}
                >
                  <ReceiveInvitationNotificationContent
                    data={data}
                    key={uuid()}
                  />
                </div>
              );
            }
            return null;
          })}
        </InfiniteScroll>
      </div>
    </Drawer>
  );
};

NotificationDrawer.propTypes = {
  className: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export { NotificationDrawer };
