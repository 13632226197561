/* eslint-disable no-useless-catch */
import * as ApiConnector from '../ApiConnector';
import { KidsAppHelper, KidsAppHelperPost } from '../../helpers/KidsAppHelper';

// eslint-disable-next-line consistent-return
export const awsGetASingleImage = async payload => {
  try {
    const { data } = await ApiConnector.post(
      KidsAppHelperPost.getAWSImageUrl,
      payload
    );
    return data;
  } catch (exception) {
    console.log(`error ${exception.message}`);
    throw exception;
  }
};
