import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  CustomInputFields,
  CustomTextArea
} from '../../../common/commonFields';
import { ChildrenAvatarLoop } from '../../common/chidrenAvatarLoop/ChildrenAvatarLoop';
import {
  diaryEntryDescriptionYupSchema,
  diaryEntryTitleYupSchema
} from '../../../common/commonFields/formValidationSchema/FormValidationSchema';
import ToastrSerivce from '../../../common/commonToastr/ToastrService';
import {
  addDiaryEntry,
  getDiaryEntry
} from '../../../../api/userDiaryEntry/UserDiaryEntryApi';
import { getSingleData } from '../../../../config/localStorage';
import { LanguageHelper } from '../../../../helpers/languageHelpers/En';

const AddDiaryEntryForm = props => {
  const { edit, id, onCancel } = props;
  const [initialValues, setInitialValues] = useState({
    diaryEntryTitle: '',
    diaryEntryDescription: ''
  });
  const [selectedChildrenData, setSelectedChildrenData] = useState([]);

  const onLoadSelectedChidData = data => {
    setSelectedChildrenData(data);
  };

  useEffect(() => {
    if (edit) {
      getDiaryEntry(id)
        .then(response => {
          const childrenData = [];
          setInitialValues({
            diaryEntryTitle: response.data.title,
            diaryEntryDescription: response.data.description
          });
          response.data.children.map(child => childrenData.push(child._id));
          setSelectedChildrenData(childrenData);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setSelectedChildrenData([]);
    }
  }, []);

  const submitData = (values, { setSubmitting }) => {
    if (selectedChildrenData.length === 0) {
      ToastrSerivce({ type: 'error', message: 'select one or more children' });
    } else {
      const diaryEntryData = {
        title: values.diaryEntryTitle,
        children: selectedChildrenData,
        content_type: 'diary-entry',
        description: values.diaryEntryDescription,
        created_by: JSON.parse(getSingleData('current-user'))._id
      };
      addDiaryEntry(diaryEntryData, id, edit)
        .then(response => {
          ToastrSerivce({
            type: 'success',
            message: response.message
          });
          setSubmitting(true);
          onCancel(false, response.data);
        })
        .catch(error => {
          console.log(error);
          ToastrSerivce({ type: 'error', message: error.message });
        });
    }
  };

  const validationSchema = Yup.object().shape({
    diaryEntryTitle: diaryEntryTitleYupSchema,
    diaryEntryDescription: diaryEntryDescriptionYupSchema
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitData}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <CustomInputFields
              name="diaryEntryTitle"
              type="text"
              label="Diary Entry Title *"
              placeholder="Type your title"
            />
            <CustomTextArea
              name="diaryEntryDescription"
              type="textarea"
              label="Diary Entry Description *"
              placeholder="Type your description here"
              rows="10"
              cols="50"
            />
            <label>{LanguageHelper.DIARY_ENTRY_KIDS_ASSIGN_LABEL} *</label>{' '}
            <br />
            <ChildrenAvatarLoop
              onLoadSelectedChidData={onLoadSelectedChidData}
              editStateChildren={selectedChildrenData}
              edit={edit}
            />
            <button
              className="button primary-btn-green"
              type="submit"
              disabled={isSubmitting}
            >
              {edit
                ? LanguageHelper.SAVE_BTN_LABEL
                : LanguageHelper.ADD_DIARY_ENTRY_BTN_LABEL}
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

export { AddDiaryEntryForm };
