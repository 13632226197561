import React from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import InputTypeErrors from '../inputTypeErrors/InputTypeErrors';

const DropDownField = ({ label, options, ...props }) => {
  const divStyle = {
    width: '100%'
  };
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = value => {
    // // this is going to call setFieldValue and manually update values.topcis
    props.onChange(props.name, value.target.value);
    setFieldValue(props.name, value.target.value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    if (props.onBlur) {
      props.onBlur(props.name, true);
    }
    setFieldTouched(props.name, true);
  };

  const makeOption = option => (
    <option key={option.key} value={option.value}>
      {option.label}
    </option>
  );

  return (
    <div className="form-group" style={divStyle}>
      <label>{label}</label>
      <select
        className="form-control form-select"
        {...field}
        {...props}
        component="select"
        onChange={handleChange}
        onBlur={handleBlur}
        value={field.value || ''}
      >
        {/* <option> {props.defaultValue}</option> */}
        {options.map(makeOption)}
      </select>

      <InputTypeErrors errors={meta} />
    </div>
  );
};
DropDownField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export { DropDownField };
